import { Box } from "@stenajs-webui/core";
import { FlatButton, stenaInfoCircle } from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import * as React from "react";
import { InfoBoxItem } from "../InfoBoxItem";
import { CustomerBox } from "../types";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";

interface Props {
  customer: CustomerBox;
  actionMenuOpen: boolean;
  openActionMenu: () => void;
  closeActionMenu: () => void;
}

export const CustomerInfoPairPopover: React.FC<Props> = ({
  customer,
  actionMenuOpen,
  openActionMenu,
  closeActionMenu,
}) => {
  const custNameUpperCase = customer.name?.toUpperCase() ?? "";
  const custStatusUpperCase = customer.custStatus?.name?.toUpperCase() ?? "";

  return (
    <Popover
      placement={"right"}
      animation={false}
      visible={actionMenuOpen}
      disablePadding
      onClickOutside={closeActionMenu}
      appendTo={document.body}
      content={
        <Box indent={2} spacing={1}>
          <InfoBoxItem
            leftContent={"Name"}
            rightContent={custNameUpperCase}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Status"}
            rightContent={custStatusUpperCase}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Rebate"}
            rightContent={customer.hasRebateAgreements ? "Yes" : "No"}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Seller"}
            rightContent={customer.seller.name}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Sales region"}
            rightContent={customer.salesRegion.name}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created"}
            rightContent={
              formatDateStringWithSwedishTimeZone({
                dateString: customer.writeHistory.createdAt.isoString,
                options: {
                  dateStyle: "short",
                  timeStyle: "short",
                },
              }) ?? ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created by"}
            rightContent={customer.writeHistory.createdBy.name ?? ""}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified"}
            rightContent={
              (customer.writeHistory.modifiedAt?.isoString &&
                formatDateStringWithSwedishTimeZone({
                  dateString: customer.writeHistory.modifiedAt?.isoString,
                  options: {
                    dateStyle: "short",
                    timeStyle: "short",
                  },
                })) ??
              ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified by"}
            rightContent={customer.writeHistory.modifiedBy?.name ?? ""}
            popoverEnabled
          />
        </Box>
      }
    >
      <Box width={16}>
        <FlatButton
          leftIcon={stenaInfoCircle}
          onClick={actionMenuOpen ? closeActionMenu : openActionMenu}
        />
      </Box>
    </Popover>
  );
};
