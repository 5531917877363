import { routeFactory } from "@/RouteFactory";
import { Crumb } from "@/common/components/bread-crumbs/Crumb";
import { PageHeader } from "@/common/components/page-header/PageHeader";
import { BreadCrumbs, stenaBusinessAgreement } from "@stenajs-webui/elements";
import * as React from "react";

interface Props {
  customerName: string;
  breadCrumbName: string;
}

export const CustomerHeader: React.FC<Props> = React.memo(
  ({ customerName, breadCrumbName }) => {
    return (
      <PageHeader
        icon={stenaBusinessAgreement}
        title={customerName}
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              path={routeFactory.productAndPrice.customer.customerList()}
              label={"Customer"}
            />
            <Crumb label={breadCrumbName} isLast />
          </BreadCrumbs>
        }
      />
    );
  }
);
