import * as React from "react";
import { useEffect } from "react";
import { Column, Spacing } from "@stenajs-webui/core";
import { RouteAgreementDetailsHeader } from "./RouteAgreementDetailsHeader";
import { RatesPanel } from "../features/rates/components/RatesPanel";
import { RoutesPanel } from "../features/routes/components/RoutesPanel";
import { ArticlesPanel } from "../features/articles/components/ArticlesPanel";
import { NotesPanel } from "../features/notes/components/NotesPanel";
import { ConditionsPanel } from "../features/conditions/components/ConditionsPanel";
import { MatrixPanel } from "../features/matrix/components/MatrixPanel";
import { RouteAgreementForForm } from "../types";

import { ActiveIndicatorBox } from "./ActiveIndicatorBox";
import { Route, Routes } from "react-router-dom";
import { RecalculationBoxForSingleRouteAgreement } from "../../../stats-calculation/recalc-stats-for-single-route-agreement-price/components/RecalculationBoxForSingleRouteAgreement";
import { routeAgreementCanBeEdited } from "../../util/RouteAgreementCalculator";
import { gql, useQuery } from "@apollo/client";
import { routeAgreementPageFragment } from "../fragments/RouteAgreementPageFragment";
import { useNegotiationYears } from "../../../customers/common/negotiation-year/NegotiationYearGate";
import { useCustomer } from "../../../customers/common/customer-head-agreement-header/UseCustomer";
import { CustomerViewInfoPairBoxes } from "../../../info-boxes/CustomerViewInfoPairBoxes";
import { ErrorScreen } from "@stenajs-webui/panels";
import { clearAndFetchStatBoxes } from "../../../../customer-route-statistic-boxes/thunks/FetchStatBoxes";
import { useDispatch } from "react-redux";
import { PageLayout } from "../../../../../common/components/page-layout/PageLayout";
import { useResultListBannerState } from "@stenajs-webui/elements";
import {
  RouteAgreementHeaderQuery,
  RouteAgreementHeaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
  routePairId: string;
  routeAgreementId: string;
  routeAgreement: RouteAgreementForForm;
}

const query = gql`
  ${routeAgreementPageFragment}
  fragment RouteAgreementHeaderRouteAgreement on RouteAgreement {
    ...RouteAgreementPageRouteAgreement
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }
  query RouteAgreementHeader($routeAgreementId: ID!, $headAgreementId: ID!) {
    productPrice {
      routeAgreement {
        byId(id: $routeAgreementId) {
          ...RouteAgreementHeaderRouteAgreement
        }
      }
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          statusCode
          name
          headAgreementNumber
          breadCrumbName
          hasSomeRouteAgreementsWithMultiLeg
          statusCode
          rowVersion
          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
          writeHistory {
            createdAt {
              isoString
            }
            createdBy {
              id
              name
            }
            modifiedAt {
              isoString
            }
            modifiedBy {
              id
              name
            }
          }
        }
      }
    }
  }
  ${routeAgreementPageFragment}
`;

export const RouteAgreementDetails: React.FC<Props> = (props) => {
  const bannerHook = useResultListBannerState(undefined);
  const dispatch = useDispatch();
  const {
    routeAgreementId,
    routeAgreement,
    routePairId,
    headAgreementId,
    customerId,
  } = props;

  const { data } = useQuery<
    RouteAgreementHeaderQuery,
    RouteAgreementHeaderQueryVariables
  >(query, {
    variables: {
      routeAgreementId,
      headAgreementId,
    },
  });

  const { defaultYear } = useNegotiationYears();
  const { customer } = useCustomer(customerId, defaultYear);

  const headAgreement = data?.productPrice.headAgreement.byId;
  const routeAgreementInfoBox = data?.productPrice.routeAgreement.byId;
  const editableRouteAgreement = routeAgreementCanBeEdited(routeAgreement);
  const currencyLabel = routeAgreement.currency.code;
  const headAgreementYear = new Date(
    routeAgreement.headAgreement.valid.start?.isoString ?? ""
  ).getUTCFullYear();

  useEffect(() => {
    dispatch(
      clearAndFetchStatBoxes({
        customerId,
        routePairId: props.routePairId,
        year: headAgreementYear,
      })
    );
  }, [dispatch, customerId, props.routePairId, headAgreementYear]);

  if (routeAgreement.multiLegAgrRouteId) {
    return (
      <Spacing num={8}>
        <ErrorScreen
          text={"The route agreement is not a sectional route agreement."}
        />
      </Spacing>
    );
  }

  return (
    <Column>
      <RouteAgreementDetailsHeader bannerHook={bannerHook} {...props} />
      <PageLayout>
        {!editableRouteAgreement && (
          <ActiveIndicatorBox status={routeAgreement.statusCode} />
        )}

        {customer && (
          <CustomerViewInfoPairBoxes
            customer={customer}
            headAgreement={headAgreement}
            headAgreementId={headAgreementId}
            routeAgreement={routeAgreementInfoBox}
            switchButtonDisabled={true}
            customerPopoverEnabled
          />
        )}

        <RecalculationBoxForSingleRouteAgreement
          routeAgreementId={routeAgreementId}
          customerId={props.customerId}
          editableRouteAgreement={editableRouteAgreement}
        />

        <Routes>
          <Route
            path={"rates"}
            element={
              <RatesPanel
                routePairId={routePairId}
                routeAgreementId={routeAgreementId}
                headAgreementId={headAgreementId}
                currencyLabel={currencyLabel}
                routeAgreement={routeAgreement}
                customerId={customerId}
                statBoxesYear={headAgreementYear}
                bannerHook={bannerHook}
              />
            }
          />
          <Route
            path={"routes"}
            element={
              <RoutesPanel
                customerId={props.customerId}
                routeAgreementId={routeAgreementId}
                isMultiLegRouteAgreement={false}
              />
            }
          />
          <Route
            path={"articles"}
            element={<ArticlesPanel routeAgreement={routeAgreement} />}
          />
          <Route path={"notes"} element={<NotesPanel />} />
          <Route path={"conditions"} element={<ConditionsPanel />} />
          <Route path={"matrix"} element={<MatrixPanel />} />
        </Routes>
      </PageLayout>
    </Column>
  );
};
