import * as React from "react";
import { Box, Heading, Row, Space, Spacing, Txt } from "@stenajs-webui/core";
import { PrimaryButton, stenaLockClosed } from "@stenajs-webui/elements";
import { ErrorPanel } from "@stenajs-webui/panels";
import LoginPagePicture from "./LoginPagePicture.svg";
import StenaLineLogo from "./StenaLineLogo.svg";
import { cssColor } from "@stenajs-webui/theme";
import { Helmet } from "react-helmet";
import { testIdConstants } from "./common/test-id-constants/testIdConstants";

interface Props {
  onPressLogin: () => void;
  error?: Error;
}

export const ErrorPage: React.FC<{ text: string }> = (props) => (
  <>
    <Spacing num={3} />
    <Row spacing indent justifyContent={"center"}>
      <Box
        spacing
        indent
        shadow={"box"}
        border={"1px solid var(--lhds-color-blue-200)"}
      >
        <ErrorPanel text={props.text} />
      </Box>
    </Row>
  </>
);

export const LoginPage: React.FC<Props> = ({ onPressLogin, error }) => {
  return (
    <>
      <Helmet title="Login - FPP" />
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"100vh"}
        height={"100vh"}
        background={cssColor("--lhds-color-ui-200")}
      >
        <Box
          height={"409px"}
          width={"723px"}
          background={cssColor("--lhds-color-blue-900")}
          position={"relative"}
          backgroundImage={`url(${LoginPagePicture})`}
          backgroundRepeat={"no-repeat"}
        >
          <Box position={"absolute"} top={"166px"} left={"56px"}>
            <Heading
              color={"white"}
              variant={"h3"}
              style={{ lineHeight: "28px" }}
            >
              {"Welcome to"}
            </Heading>
            <Heading
              color={"white"}
              variant={"h1"}
              style={{
                lineHeight: "72px",
                fontSize: "60px",
                fontWeight: 600,
              }}
            >
              {"Freight pricing"}
              <br />
              {"portal"}
            </Heading>
          </Box>
        </Box>
        <Box
          height={"120px"}
          width={"723px"}
          background={"white"}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          boxShadow={
            "0 11px 15px 0 rgba(20, 62, 98, 0.15), 0 9px 46px 8px rgba(0, 0, 0, 0.12)"
          }
        >
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Space num={7} horizontal />
            <Box
              height={"16px"}
              width={"24px"}
              backgroundImage={`url(${StenaLineLogo})`}
              backgroundRepeat={"no-repeat"}
            />
            <Space num={3} horizontal />
            <Txt
              variant={"standard"}
              size={"large"}
              color={cssColor("--lhds-color-ui-200")}
            >
              {"Made in Gothenburg"}
            </Txt>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <PrimaryButton
              label={"Login"}
              data-testid={testIdConstants.loginButton}
              size={"large"}
              onClick={onPressLogin}
              leftIcon={stenaLockClosed}
              style={{ fontWeight: "bold" }}
            />
            <Space num={7} horizontal />
          </Box>
        </Box>
        {error && <ErrorPage text={error.message} />}
      </Box>
    </>
  );
};
