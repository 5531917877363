import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import {
  RouteAgreementsRatesByFilterQuery,
  UpdateRateSheetsRouteAgreementItemInput,
} from "@/gql/graphql";
import { PrimaryButton } from "@stenajs-webui/elements";
import { first, groupBy, values } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../config/redux/RootReducer";
import { updateCustomerRouteStatisticsActualOutcome } from "../../../../customer-route-statistic-boxes/thunks/UpdateCustomerRouteStatisticsActualOutcome";
import { useUpdateRateSheetsForRouteAgreementMutation } from "../../../customer-route/hooks/UseUpdateRateSheetsForRouteAgreementMutation";
import { getModifiedOrNull } from "../../../route-agreement/details/utils/InputTransformerHelpers";
import { bulkEditRatesActions } from "../redux/reducer";
import { bulkEditRatesSelectors } from "../redux/selectors";
import { transformUpdateResultToBulkEditRatePriceTableItem } from "../transformers/BulkEditRatesTransformers";
import {
  routeAgreementHasChanges,
  transformBulkEditRatesChangesToInput,
  updateStatBoxesByUpdatedRoutePairCodes,
} from "../transformers/ModifiedToInputTransformer";
import { transformToUpdateCustomerRouteStatisticsActualOutcomeInput } from "./stat-boxes/transformers/TransformToUpdateCustomerRouteStatisticsInput";

interface Props {
  routeAgreementRates: RouteAgreementsRatesByFilterQuery | undefined;
  disabled: boolean;
}

export const BulkEditRatesSaveButton: React.FC<Props> = ({
  routeAgreementRates,
  disabled,
}) => {
  const dispatch = useDispatch();
  const selector = (state: StoreState) => state.bulkEditRatesStatBoxes;
  const bulkEditRatesStatBoxes = useSelector(selector);

  const { updateRateSheetsForRouteAgreement } =
    useUpdateRateSheetsForRouteAgreementMutation();

  const routeAgreementRatesByFilter =
    routeAgreementRates?.productPrice.routeAgreementPrice.byFilter;

  const bulkEditRatesState = useSelector(
    bulkEditRatesSelectors.getBulkEditRatesState
  );

  const submitHandler = async () => {
    dispatch(bulkEditRatesActions.clearSaveErrors());
    dispatch(bulkEditRatesActions.setSaveChangesLoading(true));
    try {
      const routeAgreementRatesByRouteAgreement = groupBy(
        routeAgreementRatesByFilter?.agrRoutePrices,
        (rar) => rar.routeAgreement.id
      );
      const routeAgreements = values(routeAgreementRatesByRouteAgreement)
        .map<UpdateRateSheetsRouteAgreementItemInput>((routeAgreementRates) => {
          const bulkEditRatesTableRowState = bulkEditRatesState;

          const routeAgreement = first(routeAgreementRates)?.routeAgreement;
          if (!bulkEditRatesTableRowState || !routeAgreement) {
            throw new Error("Missing information.");
          }
          const changes = routeAgreementRates.flatMap(
            (rate) =>
              transformBulkEditRatesChangesToInput(
                rate.id,
                bulkEditRatesTableRowState
              ).updated
          );
          return {
            routeAgreementId: routeAgreement.id,
            rowVersion: routeAgreement.rowVersion,
            isSalesReady: getModifiedOrNull(
              routeAgreement.isSalesReady,
              routeAgreement.isSalesReady
            ),
            changes: {
              created: [],
              deleted: [],
              updated: changes,
            },
          };
        })
        .filter((ra) => routeAgreementHasChanges(ra));

      const { data } = await updateRateSheetsForRouteAgreement({
        routeAgreements: routeAgreements ?? [],
      });

      const errors =
        data?.productPrice.routeAgreementPrice.updateRateSheets.flatMap(
          (sheet) => {
            if (sheet.__typename === "ErrorResult") {
              return sheet.errors;
            } else {
              return [];
            }
          }
        );

      if (errors && errors.length > 0) {
        dispatch(bulkEditRatesActions.setSaveChangesLoading(false));
        dispatch(
          bulkEditRatesActions.setSaveErrors(
            errors.map((e) => new Error(e.message))
          )
        );
        return;
      }

      const updatedRouteAgreements =
        data?.productPrice.routeAgreementPrice.updateRateSheets.flatMap(
          (sheet) => {
            if (sheet.__typename === "UpdateRateSheetsSuccessResult") {
              return sheet.routeAgreement;
            } else {
              return [];
            }
          }
        ) ?? [];

      if (updatedRouteAgreements.length > 0) {
        const bulkEditRatesPricesTableRow = updatedRouteAgreements.flatMap(
          (ra) =>
            ra.prices.map((price) =>
              transformUpdateResultToBulkEditRatePriceTableItem(ra, price)
            )
        );
        dispatch(bulkEditRatesActions.setState(bulkEditRatesPricesTableRow));
        await dispatch(
          updateCustomerRouteStatisticsActualOutcome(
            transformToUpdateCustomerRouteStatisticsActualOutcomeInput(
              updateStatBoxesByUpdatedRoutePairCodes({
                bulkEditRatesState,
                routeAgreementRatesByFilter,
                bulkEditRatesStatBoxes,
              })
            )
          )
        );
      }
    } catch (e) {
      dispatch(bulkEditRatesActions.setSaveError(e));
    } finally {
      dispatch(bulkEditRatesActions.setSaveChangesLoading(false));
    }
  };

  return (
    <PrimaryButton
      label={"Save changes"}
      data-testid={testIdConstants.bulkEditRatesSaveButton}
      disabled={disabled}
      onClick={() => {
        submitHandler();
      }}
    />
  );
};
