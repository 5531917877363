import { CustomerDetailsFragment } from "@/gql/graphql";
import { routeFactory } from "@/RouteFactory";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  stenaExternalLink,
  stenaStatisticsBar,
} from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  customerThreeYearStatisticsTableConfig,
  ThreeYearsTableRow,
} from "./CustomerThreeYearStatisticsTableConfig";

interface Props {
  customerStatistics: CustomerDetailsFragment["customerThreeYearsStatistics"];
  currencyCode: string;
}

export const CustomerThreeYearStatisticsInfoBox: React.FC<Props> = ({
  customerStatistics,
  currencyCode,
}) => {
  const navigate = useNavigate();

  const transformThreeYearStatisticsToTableRow = (
    customerStatistics: CustomerDetailsFragment["customerThreeYearsStatistics"],
    currencyCode: string
  ): Array<ThreeYearsTableRow> => {
    return customerStatistics.map((statistic) => {
      return {
        actualRate: statistic.actualRate,
        diffPrevYear: statistic.diffPreviousYear,
        expVolume: statistic.expectedVolume,
        last12MonthsVolume: statistic.last12MonthsVolume,
        negotiationStatus: statistic.negotiationStatus,
        pricePerMeter: statistic.pricePerMeter,
        targetRate: statistic.targetRate,
        year: statistic.year,
        currencyCode,
      };
    });
  };

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Statistics"}
        leftIcon={stenaStatisticsBar}
        contentRight={
          <FlatButton
            leftIcon={stenaExternalLink}
            onClick={() => navigate(routeFactory.statistics.customerTab())}
            title={"Go to customer statistics"}
          />
        }
      />
      <CardBody overflow={"auto"}>
        <StandardTable
          items={transformThreeYearStatisticsToTableRow(
            customerStatistics,
            currencyCode
          )}
          config={customerThreeYearStatisticsTableConfig}
          loading={false}
          noItemsLabel={"No customer statistics found."}
        />
      </CardBody>
    </Card>
  );
};
