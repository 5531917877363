import { SpaceValues } from "@/common/utils/SpaceValues";
import { useMsal } from "@azure/msal-react";
import { Row, Space } from "@stenajs-webui/core";
import { Link, Spinner } from "@stenajs-webui/elements";
import React from "react";
import { RebateAgreementDocumentsTableItem } from "../models";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { useDownloadPdf } from "@/features/product-and-price/head-agreement/common/hooks/UseDownloadPdf";
import { Tooltip } from "@stenajs-webui/tooltip";

export interface PreviewPdf {
  item: RebateAgreementDocumentsTableItem;
  error: FreightPricingPortalError | undefined;
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >;
  ellipsis?: { useEllipsis: boolean; breakWidth: string };
}

export const PreviewPdfLink: React.FC<PreviewPdf> = ({
  item,
  error,
  setError,
  ellipsis,
}) => {
  const { instance } = useMsal();
  const { handleDownload, loading } = useDownloadPdf(instance, setError);
  const onClick = () => {
    const customerNumber = String(item.customerNumber);
    handleDownload(
      `/download/rebate-agreement-document?customerNumber=${customerNumber}&documentId=${item.id}`,
      item.fileName,
      true
    );
  };

  return (
    <Tooltip label={item.fileName} disabled={!ellipsis?.useEllipsis}>
      <Row alignItems={"center"} indent>
        <Link
          onClick={onClick}
          style={
            ellipsis?.useEllipsis
              ? {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: `${ellipsis.breakWidth}px`,
                  whiteSpace: "nowrap",
                }
              : undefined
          }
        >
          {item.fileName}
        </Link>
        <Space num={loading ? SpaceValues.SIXTEEN : SpaceValues.THIRTYSIX} />
        {loading && <Spinner size="tiny" />}
      </Row>
    </Tooltip>
  );
};
