import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { Indent, Text } from "@stenajs-webui/core";
import { StandardTableConfig } from "@stenajs-webui/grid";
import { DownloadPdfButton } from "../../head-agreement/agreement-documents/components/DownloadPdfButton";
import { PreviewPdfLink } from "../../head-agreement/agreement-documents/components/PreviewPdfLink";

export interface DocumentTableRow {
  id: string;
  agreementId: string;
  fileName: string;
  agreementDocumentType: string;
  negotiationYear: number;
  agreementValidFromDate: string;
  agreementValidToDate: string;
  writeHistory: {
    createdAt: {
      isoString: string;
    };
    createdBy: {
      id: string;
      name: string;
    };
  };
  customerNumber: number;
}
type DocumentColumnKeys =
  | keyof Omit<
      DocumentTableRow,
      | "customerNumber"
      | "agreementId"
      | "agreementValidFromDate"
      | "agreementValidToDate"
      | "writeHistory"
      | "id"
    >
  | "validity"
  | "download";
const ellipsisBreakWidth = "320";
const fileNameColumnWidth = "330px";
export const documentsConfig = (
  error: FreightPricingPortalError | undefined,
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >
) => {
  const documentsConfig: StandardTableConfig<
    DocumentTableRow,
    DocumentColumnKeys
  > = {
    initialSortOrder: "validity",
    keyResolver: (item) => item.id,
    columns: {
      fileName: {
        itemValueResolver: (item) => item.fileName,
        renderCell: ({ label, value, item }) => (
          <PreviewPdfLink
            item={item}
            error={error}
            setError={setError}
            ellipsis={{ useEllipsis: true, breakWidth: ellipsisBreakWidth }}
          />
        ),
        columnLabel: "Filename",
        width: fileNameColumnWidth,
      },
      agreementDocumentType: {
        itemValueResolver: (item) => item.agreementDocumentType,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Text>{item.agreementDocumentType}</Text>
          </Indent>
        ),
        columnLabel: "Type",
      },
      negotiationYear: {
        itemValueResolver: (item) => item.negotiationYear,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Text>{item.negotiationYear}</Text>
          </Indent>
        ),
        minWidth: "145px",
        columnLabel: "Negotiation year",
      },
      validity: {
        itemValueResolver: (item) => item.agreementValidFromDate,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Text>
              {formatDateStringRangeWithSwedishTimeZone({
                dateStringFrom: item.agreementValidFromDate,
                dateStringTo: item.agreementValidToDate,
              })}
            </Text>
          </Indent>
        ),
        minWidth: "190px",
        columnLabel: "Validity",
      },
      download: {
        itemValueResolver: (item) => item.id,
        renderCell: ({ label, value, item }) => (
          <DownloadPdfButton item={item} error={error} setError={setError} />
        ),
        columnLabel: "",
        width: "40px",
        justifyContentCell: "flex-end",
      },
    },

    columnOrder: [
      "fileName",
      "agreementDocumentType",
      "negotiationYear",
      "validity",
      "download",
    ],
  };
  return documentsConfig;
};
