import { Property } from "csstype";
import * as React from "react";
import { GridContainer } from "../../../common/components/grid-container/GridContainer";
import { CustomerInfoPairBox } from "./customer/CustomerInfoPairBox";
import { RouteAgreementInfoPairBox } from "./route-agreement/RouteAgreementInfoPairBox";
import {
  CustomerHeaderCustomersByIdFragment,
  RouteAgreementHeaderRouteAgreementFragment,
} from "@/gql/graphql";
import { HeadAgreementInfoPairBox } from "./head-agreement/head-agreement-info-pair-box/HeadAgreementInfoPairBox";

interface Props {
  headAgreementId: string;
  headAgreement?: any;
  customer: CustomerHeaderCustomersByIdFragment;
  routeAgreement?:
    | RouteAgreementHeaderRouteAgreementFragment
    | null
    | undefined;
  justifyContentRow?: Property.JustifyContent;
  alignItemsRow?: Property.AlignItems;
  indentBetweenBoxes?: number;
  switchButtonDisabled?: boolean;
  showDocumentsButton?: boolean;
  customerPopoverEnabled?: boolean;
}

export const CustomerViewInfoPairBoxes: React.FC<Props> = ({
  headAgreementId,
  headAgreement,
  routeAgreement,
  customer,
  switchButtonDisabled,
  showDocumentsButton,
  customerPopoverEnabled,
}) => {
  return (
    <GridContainer boxMinWidth={300}>
      {routeAgreement && (
        <RouteAgreementInfoPairBox routeAgreement={routeAgreement} />
      )}

      {headAgreementId && headAgreement && (
        <HeadAgreementInfoPairBox
          headAgreement={headAgreement}
          customerId={customer.id}
          switchButtonDisabled={switchButtonDisabled}
        />
      )}

      <CustomerInfoPairBox
        customer={customer}
        documentButtonEnabled={showDocumentsButton}
        popoverEnabled={customerPopoverEnabled}
      />
    </GridContainer>
  );
};
