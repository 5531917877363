import { AppThunk } from "../../../config/redux/RootReducer";
import { apolloClient } from "../../../apollo-client/ApolloClient";
import { gql } from "@apollo/client";
import {
  UpdateCustomerRouteStatisticsActualOutcomeInput,
  UpdateCustomerRouteStatisticsActualOutcomeMutation,
  UpdateCustomerRouteStatisticsActualOutcomeMutationVariables,
} from "@/gql/graphql";

const mutation = gql`
  mutation UpdateCustomerRouteStatisticsActualOutcome(
    $input: [UpdateCustomerRouteStatisticsActualOutcomeInput!]!
  ) {
    productPrice {
      customerRouteStatistics {
        updateCustomerRouteStatisticsActualOutcome(input: $input) {
          customerRouteStatistics {
            custNo
            routePairCode
            year
            sailingCategoryCode
            unitCategoryCode
            actualOutcomeInPercent
          }
        }
      }
    }
  }
`;

export const updateCustomerRouteStatisticsActualOutcome =
  (input: UpdateCustomerRouteStatisticsActualOutcomeInput[]): AppThunk =>
  async () => {
    if (input.length > 0) {
      await apolloClient.mutate<
        UpdateCustomerRouteStatisticsActualOutcomeMutation,
        UpdateCustomerRouteStatisticsActualOutcomeMutationVariables
      >({
        mutation,
        variables: {
          input: input,
        },
      });
    }
  };
