import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import {
  commaToDotTransform,
  dotToCommaTransform,
} from "@/common/formatters/NumericTextFieldInputCleaner";
import { Box, Indent, Row } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import React from "react";
import { ArticleItemFormModel } from "../models/ArticlesFormModel";

interface Props {
  maxNumberOf: string;
  maxPrice: string;
  setFields: (fields: Partial<ArticleItemFormModel>) => void;
}

const maxLengthOfMaxNoOfInDb = 3; // numeric (3
const maxLengthOfMaxPriceInDb = 7; // numeric (9,2)

const ArticleItemMaxPriceAndNumber: React.FC<Props> = ({
  maxNumberOf,
  maxPrice,
  setFields,
}) => {
  return (
    <Box>
      <Row>
        <Box width={88}>
          <Label text={"Max price"}>
            <NumericTextInput
              width={80}
              maxLength={maxLengthOfMaxPriceInDb}
              value={dotToCommaTransform(maxPrice)}
              onValueChange={(maxPrice) =>
                setFields({ maxPrice: commaToDotTransform(maxPrice) })
              }
            />
          </Label>
        </Box>
        <Indent num={2} />
        <Box width={88}>
          <Label text={"Max number of"}>
            <NumericTextInput
              value={maxNumberOf}
              maxDecimals={0}
              maxNumbers={maxLengthOfMaxNoOfInDb}
              onValueChange={(maxNumberOf) => setFields({ maxNumberOf })}
            />
          </Label>
        </Box>
      </Row>
    </Box>
  );
};

export default ArticleItemMaxPriceAndNumber;
