import * as React from "react";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../../../RouteFactory";
import { PageHeaderItem } from "../../../../common/components/page-header/PageHeaderItem";

import {
  BreadCrumbs,
  CardBody,
  CardHeader,
  FlatButton,
  Icon,
  stenaBusinessAgreement,
  stenaInfoCircle,
  stenaUserCircle,
} from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { Box } from "@stenajs-webui/core";

import { CustomerBaseInformation } from "@/features/product-and-price/info-boxes/customer/CustomerBaseInformation";
import {
  HeadAgreementStatusCode,
  ReportScreenCustomerFragment,
  ReportScreenHeadagreementFragment,
} from "@/gql/graphql";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { HeadAgreementInfoCardBody } from "@/features/product-and-price/info-boxes/head-agreement/head-agreement-info-pair-box/HeadAgreementInfoCardBody";

interface Props {
  loading: boolean;
  customerId: string;
  headAgreementId: string;
  customer: ReportScreenCustomerFragment | null;
  headAgreement: ReportScreenHeadagreementFragment | null;
  reportType?: string;
  name?: string;
}

export const ReportHeader: React.FC<Props> = ({
  loading,
  customerId,
  headAgreementId,
  customer,
  reportType,
  headAgreement,
}) => {
  return (
    <PageHeader
      title={
        reportType
          ? `Create ${reportType} - ${customer?.name || ""} (${customerId})`
          : "Create report"
      }
      loading={loading}
      contentLeft={
        <Popover
          trigger={"click"}
          arrow={false}
          zIndex={2}
          appendTo={document.body}
          content={
            <Box zIndex={ZIndex.highest} minWidth={"490px"}>
              <CardHeader
                text={"Header agreement"}
                headingLevel={"h2"}
                contentLeft={<Icon icon={stenaBusinessAgreement}></Icon>}
              />
              {headAgreement && (
                <HeadAgreementInfoCardBody
                  headAgreement={{
                    id: headAgreementId,
                    headAgreementNumber: headAgreement?.headAgreementNumber,
                    valid: headAgreement?.valid,
                    hasSomeRouteAgreementsWithMultiLeg:
                      headAgreement?.multiLegAgrRoutes?.length > 0 || false,
                    name: headAgreement?.name,
                    statusCode: HeadAgreementStatusCode.Active,
                  }}
                />
              )}
              {customer && (
                <>
                  <CardHeader
                    text={"Customer"}
                    headingLevel={"h2"}
                    contentLeft={<Icon icon={stenaUserCircle}></Icon>}
                  />
                  <CardBody
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    variant={"compact"}
                  >
                    <CustomerBaseInformation
                      custIndex={customer?.custIndex || ""}
                      custNo={customer?.custNo}
                      custStatNo={customer?.statisticalGroupingId}
                      custInvoiceCurrency={customer.invoiceCurrency.code}
                    />
                  </CardBody>
                </>
              )}
            </Box>
          }
        >
          <FlatButton leftIcon={stenaInfoCircle} />
        </Popover>
      }
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          <Crumb
            label={customer?.breadCrumbName ?? ""}
            path={
              headAgreementId
                ? routeFactory.productAndPrice.customer.customerHeadAgreement({
                    customerId,
                    headAgreementId,
                  })
                : routeFactory.productAndPrice.customer.customer({
                    customerId,
                  })
            }
          />
          <Crumb label={"Create report"} isLast />
        </BreadCrumbs>
      }
    >
      <PageHeaderItem label={"Cust. no."} value={customer?.custNo} />
      <PageHeaderItem label={"Cust. name"} value={customer?.name} />
      <PageHeaderItem label={"Cust. index"} value={customer?.custIndex} />
    </PageHeader>
  );
};
