import { negotiationStatusCodeTransformer } from "@/common/formatters/NegotiationStatusCodeTransformer";
import {
  currencyValue,
  percentLabelFormatterDecimalValue,
} from "@/features/statistics/common/utils/statisticsUtil";
import { Indent, Text } from "@stenajs-webui/core";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";

export interface ThreeYearsTableRow {
  year: number;
  negotiationStatus: string | null;
  last12MonthsVolume: number;
  expVolume: number;
  targetRate: number;
  actualRate: number;
  pricePerMeter: number;
  diffPrevYear: number | null;
  currencyCode: string;
}

type ThreeYearsColumnKeys = keyof Omit<ThreeYearsTableRow, "currencyCode">;

export const customerThreeYearStatisticsTableConfig: StandardTableConfig<
  ThreeYearsTableRow,
  ThreeYearsColumnKeys
> = {
  initialSortOrder: "year",
  initialSortOrderDesc: true,
  keyResolver: (item) => String(item.year),
  columns: {
    year: createColumnConfig((item) => item.year, {
      columnLabel: "Year",
      renderCell: ({ label, item }) => (
        <Indent>
          <Text>{item.year}</Text>
        </Indent>
      ),
      disableGridCell: true,
    }),
    negotiationStatus: createColumnConfig(
      (item) => negotiationStatusCodeTransformer(item.negotiationStatus ?? ""),
      {
        columnLabel: "Negotiation status",
        width: "160px",
      }
    ),
    last12MonthsVolume: createColumnConfig((item) => item.last12MonthsVolume, {
      columnLabel: "12 mon. vol",
      renderCell: ({ label, item }) => (
        <Indent>
          <Text>{item.last12MonthsVolume}</Text>
        </Indent>
      ),
      minWidth: "110px",
      disableGridCell: true,
    }),
    expVolume: createColumnConfig((item) => item.expVolume, {
      columnLabel: "Exp. vol.",
      renderCell: ({ label, item }) => (
        <Indent>
          <Text>{item.expVolume}</Text>
        </Indent>
      ),
      minWidth: "100px",
      disableGridCell: true,
    }),
    targetRate: {
      itemValueResolver: ({ actualRate }) => actualRate,
      columnLabel: "Target rate",
      itemLabelFormatter: percentLabelFormatterDecimalValue,
      minWidth: "110px",
    },
    actualRate: createColumnConfig((item) => item.actualRate, {
      columnLabel: "Actual rate",
      itemLabelFormatter: percentLabelFormatterDecimalValue,
      minWidth: "110px",
    }),
    pricePerMeter: {
      itemValueResolver: ({ pricePerMeter }) =>
        pricePerMeter === null || pricePerMeter === 0
          ? -Infinity
          : pricePerMeter,
      columnLabel: "Price/m",
      renderCell: ({ item }) =>
        currencyValue(item.pricePerMeter, item.currencyCode),
    },
    diffPrevYear: {
      itemValueResolver: ({ diffPrevYear }) =>
        diffPrevYear === null ? -Infinity : diffPrevYear,
      columnLabel: "Diff prev year",
      minWidth: "130px",
      renderCell: ({ item }) =>
        percentLabelFormatterDecimalValue(item.diffPrevYear),
    },
  },

  columnOrder: [
    "year",
    "negotiationStatus",
    "last12MonthsVolume",
    "expVolume",
    "targetRate",
    "actualRate",
    "pricePerMeter",
    "diffPrevYear",
  ],
};
