import { routeFactory } from "@/RouteFactory";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";
import { Link } from "@/common/navigation/Link";
import { Row, Txt, useBoolean } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  Icon,
  stenaBan,
  stenaDetails,
  stenaLockClosed,
  stenaUserCircle,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { InfoBoxItem } from "../InfoBoxItem";
import { CustomerBox } from "../types";
import { CustomerInfoPairPopover } from "./CustomerInfoPairPopover";
import { CustomerBaseInformation } from "./CustomerBaseInformation";
import { CustomerStatusCode } from "@/gql/graphql";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";

const ParentChildrenLink: React.FC<{
  customer: CustomerBox;
  linkDisabled?: boolean;
}> = ({ customer, linkDisabled }) => {
  return customer.isParent ? (
    linkDisabled ? (
      <>{`Parent (${customer.childCustomers.length} ${
        customer.childCustomers.length === 1 ? "child" : "children"
      })`}</>
    ) : (
      <Link
        to={routeFactory.productAndPrice.customer.childCustomers({
          customerId: String(customer.custNo),
        })}
      >
        <Txt size="small" color="unset">
          {`Parent (${customer.childCustomers.length} ${
            customer.childCustomers.length === 1 ? "child" : "children"
          })`}
        </Txt>
      </Link>
    )
  ) : linkDisabled ? (
    <>{`Child of #${customer.parent?.id}`}</>
  ) : (
    <Link
      to={routeFactory.productAndPrice.customer.customerHeadAgreement({
        customerId: customer.parent?.id ?? "",
        headAgreementId: customer.parent?.preselectedHeadAgreement?.id ?? "",
      })}
    >
      <Txt size="small" color="unset">
        {`Child of #${customer.parent?.id}`}
      </Txt>
    </Link>
  );
};
interface Props {
  customer: CustomerBox;
  parentChildLinkDisabled?: boolean;
  popoverEnabled?: boolean;
  documentButtonEnabled?: boolean;
}

export const CustomerInfoPairBox: React.FC<Props> = ({
  customer,
  parentChildLinkDisabled,
  popoverEnabled,
  documentButtonEnabled,
}) => {
  const navigate = useNavigate();
  const [actionMenuOpen, openActionMenu, closeActionMenu] = useBoolean(false);

  const custStatusUpperCase = customer.custStatus?.name?.toUpperCase() ?? "";
  const custNameUpperCase = customer.name?.toUpperCase() ?? "";

  const getStatusIcon = () => {
    if (custStatusUpperCase === CustomerStatusCode.Blocked) {
      return stenaBan;
    }
    if (custStatusUpperCase === CustomerStatusCode.Closed) {
      return stenaLockClosed;
    }
    return undefined;
  };

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Customer"}
        leftIcon={stenaUserCircle}
        contentLeft={
          <Icon
            icon={getStatusIcon()}
            size={16}
            color={cssColor("--lhds-color-ui-700")}
          />
        }
        contentRight={
          <Row>
            {documentButtonEnabled && (
              <FlatButton
                label="Documents"
                leftIcon={stenaDetails}
                data-testid={testIdConstants.headerAgreementDocumentsButton}
                onClick={() =>
                  navigate(
                    routeFactory.productAndPrice.headAgreement.agreementDocuments(
                      {
                        customerNo: String(customer.custNo),
                      }
                    )
                  )
                }
              />
            )}
            {popoverEnabled && (
              <CustomerInfoPairPopover
                customer={customer}
                actionMenuOpen={actionMenuOpen}
                openActionMenu={openActionMenu}
                closeActionMenu={closeActionMenu}
              />
            )}
          </Row>
        }
      />
      <CardBody flexDirection={"row"} flexWrap={"wrap"} variant={"compact"}>
        <CustomerBaseInformation
          custIndex={customer.custIndex}
          custNo={customer.custNo}
          custStatNo={customer.statisticalGrouping?.id ?? ""}
          custInvoiceCurrency={customer.invoiceCurrency.code}
        />
        {(!customer.isParent || customer.childCustomers.length > 0) && (
          <InfoBoxItem
            leftContent={"Type"}
            rightContent={
              <ParentChildrenLink
                customer={customer}
                linkDisabled={parentChildLinkDisabled}
              />
            }
            key={"customer-infobox-key-3"}
          />
        )}
        {!popoverEnabled && (
          <>
            <InfoBoxItem
              leftContent={"Name"}
              rightContent={custNameUpperCase}
            />
            <InfoBoxItem
              leftContent={"Status"}
              rightContent={custStatusUpperCase}
            />
            <InfoBoxItem
              leftContent={"Rebate"}
              rightContent={customer.hasRebateAgreements ? "Yes" : "No"}
            />
            <InfoBoxItem
              leftContent={"Seller"}
              rightContent={customer.seller.name}
            />
            <InfoBoxItem
              leftContent={"Sales region"}
              rightContent={customer.salesRegion.name}
            />
            <InfoBoxItem
              leftContent={"Created"}
              rightContent={
                formatDateStringWithSwedishTimeZone({
                  dateString: customer.writeHistory.createdAt.isoString,
                  options: {
                    dateStyle: "short",
                    timeStyle: "short",
                  },
                }) ?? ""
              }
            />
            <InfoBoxItem
              leftContent={"Created by"}
              rightContent={customer.writeHistory.createdBy.name ?? ""}
            />
            <InfoBoxItem
              leftContent={"Modified"}
              rightContent={
                (customer.writeHistory.modifiedAt?.isoString &&
                  formatDateStringWithSwedishTimeZone({
                    dateString: customer.writeHistory.modifiedAt?.isoString,
                    options: {
                      dateStyle: "short",
                      timeStyle: "short",
                    },
                  })) ??
                ""
              }
            />
            <InfoBoxItem
              leftContent={"Modified by"}
              rightContent={customer.writeHistory.modifiedBy?.name ?? ""}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
};
