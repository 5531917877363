import { GridContainer } from "@/common/components/grid-container/GridContainer";
import { PageLayout } from "@/common/components/page-layout/PageLayout";
import { CustomerInfoPairBox } from "@/features/product-and-price/info-boxes/customer/CustomerInfoPairBox";
import { NoHeadAgreementExistBox } from "@/features/product-and-price/info-boxes/head-agreement/head-agreement-info-pair-box/NoHeadAgreementExistBox";
import {
  CustomerNoExistingHaPanelQuery,
  CustomerNoExistingHaPanelQueryVariables,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { Spacing } from "@stenajs-webui/core";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useHeadAgreementsByCustomer } from "../../../head-agreement/list/hooks/UseHeadAgreementsSwitcher";
import { CustomerHeader } from "./CustomerHeader";
import { useParams } from "react-router-dom";

const query = gql`
  fragment CustomerNoExistingHa on Customer {
    id
    name
    breadCrumbName
    custIndex
    custNo
    isParent
    custStatus {
      id
      name
    }
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }
    childCustomers {
      id
    }
    statisticalGrouping {
      id
    }
    invoiceCurrency {
      id
      code
    }
    seller {
      id
      name
    }
    salesRegion {
      id
      code
      name
    }
    crmLink
    isStandardCustomer
    hasRebateAgreements
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
  }
  query CustomerNoExistingHaPanel($customerId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...CustomerNoExistingHa
        }
      }
    }
  }
`;

export const CustomerNoExistingHa: React.FC = () => {
  const { customerId } = useParams();
  const { loading, error, data } = useQuery<
    CustomerNoExistingHaPanelQuery,
    CustomerNoExistingHaPanelQueryVariables
  >(query, {
    variables: {
      customerId: customerId ?? "",
    },
  });

  const { headAgreements, loading: headAgreementsByCustomerLoading } =
    useHeadAgreementsByCustomer(customerId ?? "");

  const customer = data?.productPrice.customers.byId;

  if (loading || headAgreementsByCustomerLoading) {
    return (
      <Spacing num={6}>
        <LoadingScreen />
      </Spacing>
    );
  }

  if (error) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={error.message} />
      </Spacing>
    );
  }

  if (!customer) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={"No such customer."} />
      </Spacing>
    );
  }

  return (
    <>
      <CustomerHeader
        customerName={customer.name}
        breadCrumbName={customer.breadCrumbName}
      />
      <PageLayout>
        {headAgreements.length === 0 && (
          <GridContainer boxMinWidth={300}>
            <NoHeadAgreementExistBox customerId={customerId ?? ""} />
            <CustomerInfoPairBox
              customer={customer}
              popoverEnabled={true}
              documentButtonEnabled
            />
          </GridContainer>
        )}
      </PageLayout>
    </>
  );
};
