import { RouteAgreementDetailsScreenParams } from "./features/product-and-price/route-agreement/details/components/RouteAgreementDetailsScreen";
import { CustomerRouteScreenParams } from "./features/product-and-price/customer-route/components/CustomerRoutePairScreen";
import { CustomerHeadAgreementScreenParams } from "./features/product-and-price/head-agreement/details/components/CustomerHeadAgreementScreen";
import { CustomerScreenParams } from "./features/product-and-price/customers/customer-details/components/CustomerScreen";
import { EditHeadAgreementScreenParams } from "./features/product-and-price/head-agreement/edit/components/EditHeadAgreementScreen";
import { CreateHeadAgreementScreenParams } from "./features/product-and-price/head-agreement/create/components/CreateHeadAgreementScreen";
import { CustomerExpectedVolumeScreenParams } from "./features/product-and-price/expected-volume/components/CustomerExpectedVolumeScreen";
import {
  AgrRoute,
  SectionalRouteAgreements,
} from "./features/product-and-price/head-agreement/activate/components/ActivateHeadAgreementContainer";
import { AgreementDocumentsScreenParams } from "./features/product-and-price/head-agreement/agreement-documents/components/AgreementDocumentsScreen";
import { ChildCustomersScreenParams } from "./features/product-and-price/customers/child-customers/components/ChildCustomersScreen";

export interface RouteTargetScreenParams {
  routePairCode: string;
}

// TODO -> Place this in the multi leg route agreement details screen once it is constructed.
export type MultiLegRouteAgreementDetailsTab =
  | "rates"
  | "routes"
  | "articles"
  | "notes"
  | "conditions"
  | "matrix";

export interface MultiLegRouteAgreementDetailsScreenParams {
  customerId: string;
  headAgreementId: string;
  routeAgreementId: string;
  multiLegAgreementId: string;
  tab?: MultiLegRouteAgreementDetailsTab;
}

export interface MultiLegAgreementDetailsScreenParams {
  customerId: string;
  headAgreementId: string;
  multiLegAgreementId: string;
}

const extractPathParts = (path: string): string[] => {
  return path.substring(0, 1) === "/"
    ? path.substring(1).split("/")
    : path.split("/");
};

interface ReportScreenParams {
  customerId: string;
  headAgreementId: string;
  reportType: string;
  parent?: string;
}

export const extractInfoFromPath = (
  path?: string
): PathIdentifiers | undefined => {
  if (!path) {
    return undefined;
  }
  const pathArray = extractPathParts(path);
  const agreementIdPart =
    pathArray.length > 2 && pathArray[0] === "Agreement"
      ? pathArray[1].replace("[", "").replace("]", "")
      : undefined;
  const agreementId =
    agreementIdPart && agreementIdPart.indexOf(",") > -1
      ? agreementIdPart?.split(",")[0]
      : undefined;
  const agreementNumber =
    agreementIdPart && agreementIdPart.indexOf(",") > -1
      ? agreementIdPart?.split(",")[1]
      : undefined;

  const agrRouteIdPart =
    pathArray.length > 4 && pathArray[2] === "AgrRoutes"
      ? pathArray[3].replace("[", "").replace("]", "")
      : undefined;
  const agrRouteId =
    agrRouteIdPart && agrRouteIdPart.indexOf(",") > -1
      ? agrRouteIdPart?.split(",")[0]
      : undefined;
  const agrRouteNumber =
    agrRouteIdPart && agrRouteIdPart.indexOf(",") > -1
      ? agrRouteIdPart?.split(",")[1]
      : undefined;
  return { agreementId, agreementNumber, agrRouteId, agrRouteNumber };
};

interface PathIdentifiers {
  agreementId?: string;
  agreementNumber?: string;
  agrRouteId?: string;
  agrRouteNumber?: string;
}

const createLinkToErroredResource = (
  customerId: string,
  headAgreementId: string,
  sectionalRouteAgreements: SectionalRouteAgreements,
  apiPathToResource: string
): string => {
  const pathIdentifiers = extractInfoFromPath(apiPathToResource);
  const routeAgreement = sectionalRouteAgreements.find(
    (routeAgreement: AgrRoute) =>
      routeAgreement.id === pathIdentifiers?.agrRouteId
  );
  if (routeAgreement && routeAgreement.routes.length > 0) {
    const searchParams: RouteAgreementDetailsScreenParams = {
      customerId,
      headAgreementId,
      routeAgreementId: routeAgreement.id,
      routePairId: routeAgreement?.routes[0].routePair.code,
      tab: "rates",
    };
    return routeFactory.productAndPrice.routeAgreement.routeAgreementDetails(
      searchParams
    );
  }
  return "";
};

export const routeFactory = {
  appRoot: () => "/",
  targetingTool: {
    routeTargets: {
      allRoutes: () => "/routes-targets",
      allRoutesMatch: () => "/routes-targets/*",
      routeTarget: ({ routePairCode }: RouteTargetScreenParams) =>
        `/routes-targets/${routePairCode}`,
    },
    adminTarget: {
      allRoutes: () => "/admin/group-freight-targets",
    },
  },
  admin: {
    surchargeDocuments: () => "/admin/surcharge-documents",
  },
  productAndPrice: {
    productAndPriceApplication: () => "/negotiation-plan/*",
    linkToErroredResource: (
      customerId: string,
      headAgreementId: string,
      sectionalRouteAgreements: SectionalRouteAgreements,
      apiPathToResource: string
    ) =>
      createLinkToErroredResource(
        customerId,
        headAgreementId,
        sectionalRouteAgreements,
        apiPathToResource
      ),
    customer: {
      customerList: () => "/negotiation-plan",
      customer: ({ customerId }: CustomerScreenParams) =>
        `/negotiation-plan/${customerId}`,
      customerNoHeadAgreement: ({ customerId }: CustomerScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement`,
      customerHeadAgreement: ({
        customerId,
        headAgreementId,
      }: CustomerHeadAgreementScreenParams) =>
        headAgreementId
          ? `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}`
          : `/negotiation-plan/${customerId}`,
      customerRoutePair: ({
        routePairId,
        headAgreementId,
        customerId,
      }: CustomerRouteScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/route-pair/${routePairId}`,
      customerExpectedVolume: ({
        customerId,
      }: CustomerExpectedVolumeScreenParams) =>
        `/negotiation-plan/${customerId}/expected-volume`,
      childCustomers: ({ customerId }: ChildCustomersScreenParams) =>
        `/negotiation-plan/${customerId}/child-customers`,
    },
    headAgreement: {
      activateHeadAgreement: ({
        customerId,
        headAgreementId,
      }: CustomerHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/activate`,

      copyHeadAgreement: ({
        customerId,
        headAgreementId,
      }: CustomerHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/copy`,

      copyHeadAgreementToOtherCustomer: ({
        customerId,
        headAgreementId,
      }: CustomerHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/copy-to-other-customer`,

      agreementArticles: ({
        customerId,
        headAgreementId,
      }: CustomerHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/agreement-articles`,

      createReport: ({
        customerId,
        headAgreementId,
        reportType,
        parent,
      }: ReportScreenParams) =>
        parent
          ? `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/create-report-wizard/${reportType}/${parent}`
          : `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/create-report-wizard/${reportType}`,

      editHeadAgreement: ({
        customerId,
        headAgreementId,
      }: EditHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/edit`,

      bulkEditRateHeadAgreement: ({
        customerId,
        headAgreementId,
      }: EditHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/edit-rates`,

      createHeadAgreement: ({ customerId }: CreateHeadAgreementScreenParams) =>
        `/negotiation-plan/${customerId}/create`,
      agreementDocuments: ({ customerNo }: AgreementDocumentsScreenParams) =>
        `/negotiation-plan/${customerNo}/agreement-documents`,
      rebateAgreementDocuments: ({
        customerNo,
      }: AgreementDocumentsScreenParams) =>
        `/negotiation-plan/${customerNo}/rebate-documents`,
    },
    routeAgreement: {
      createRouteAgreement: ({ ...base }: CustomerHeadAgreementScreenParams) =>
        `${routeFactory.productAndPrice.customer.customerHeadAgreement(
          base
        )}/${routeFactory.productAndPrice.routeAgreement.createRouteAgreementSubPath()}`,

      createRouteAgreementSubPath: () => `create-route-agreement`,
      routeAgreementDetails: ({
        routeAgreementId,
        routePairId,
        headAgreementId,
        customerId,
        tab,
      }: RouteAgreementDetailsScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/route-pair/${routePairId}/route-agreement/${routeAgreementId}${
          tab ? "/" + tab : ""
        }`,
    },
    multiLegAgreement: {
      createMultiLegRouteAgreement: ({
        ...base
      }: CustomerHeadAgreementScreenParams) =>
        `${routeFactory.productAndPrice.customer.customerHeadAgreement(base)}/${
          routeFactory.productAndPrice.multiLegAgreement
            .createMultilegRouteAgreementSubPath
        }`,
      createMultilegRouteAgreementSubPath: `create-multileg-route-agreement`,

      multiLegAgreementDetails: ({
        customerId,
        headAgreementId,
        multiLegAgreementId,
      }: MultiLegAgreementDetailsScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/multi-leg-route/${multiLegAgreementId}`,
      multiLegRouteAgreementDetails: ({
        routeAgreementId,
        headAgreementId,
        customerId,
        multiLegAgreementId,
        tab,
      }: MultiLegRouteAgreementDetailsScreenParams) =>
        `/negotiation-plan/${customerId}/head-agreement/${headAgreementId}/multi-leg-route/${multiLegAgreementId}/route-agreement/${routeAgreementId}${
          tab ? "/" + tab : ""
        }`,
    },
  },
  rebates: {
    rebatesRoot: () => `/rebates`,
  },
  statistics: {
    statisticsRoot: () => `/statistics`,
    totalTab: () => `${routeFactory.statistics.statisticsRoot()}/total`,
    salesRegionTab: () =>
      `${routeFactory.statistics.statisticsRoot()}/sales-region`,
    salesRegionDetails: (salesRegionId: string) =>
      `${routeFactory.statistics.statisticsRoot()}/sales-region/${salesRegionId}`,
    routeTab: () => `${routeFactory.statistics.statisticsRoot()}/route`,
    routeDetails: (routeId: string) =>
      `${routeFactory.statistics.statisticsRoot()}/route/${routeId}`,
    sellerTab: () => `${routeFactory.statistics.statisticsRoot()}/seller`,
    customerTab: () => `${routeFactory.statistics.statisticsRoot()}/customer`,
    advancedTab: () => `${routeFactory.statistics.statisticsRoot()}/advanced`,
    sellerDetails: (sellerId: string) =>
      `${routeFactory.statistics.statisticsRoot()}/seller/${encodeURI(
        sellerId
      )}`,
  },
  priceCalculator: {
    priceCalculatorRoot: () => `/price-calculator`,
  },
  noShowLateHandling: {
    routePrices: () => "/nslh/routes-prices",
    handleBookings: () => "/nslh/handle-bookings",
    invoiceSpecification: (invoiceNumber: string) =>
      `/nslh/follow-up/invoice-specification/${invoiceNumber}`,
    followUpTabView: (tab: string) =>
      tab ? "/nslh/follow-up/" + tab : "/nslh/follow-up/",
    oneMonthBookings: (accountNo: string, year: string, month: string) =>
      `/nslh/follow-up/bookings/${accountNo},${year},${month}`,
  },
};
