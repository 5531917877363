import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { Indent, Text } from "@stenajs-webui/core";
import { HeadAgreementStatusCodeChip } from "@/common/components/chips/HeadAgreementStatusCodeChip";
import { HeadAgreementStatusCode } from "@/gql/graphql";
import { Link } from "@/common/navigation/Link";
import { routeFactory } from "@/RouteFactory";
import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";

export interface TopAgreementsTableRow {
  status: HeadAgreementStatusCode;
  agreementNumber: number;
  name: string;
  validity: {
    from?: string;
    to?: string;
  };
  headAgreementId: string;
  customerId: string;
}

type TopAgreementsColumnKeys = keyof Omit<
  TopAgreementsTableRow,
  "headAgreementId" | "customerId"
>;

export const topAgreementsConfig: StandardTableConfig<
  TopAgreementsTableRow,
  TopAgreementsColumnKeys
> = {
  initialSortOrder: "validity",
  keyResolver: (item) => String(item.agreementNumber),
  columns: {
    status: createColumnConfig((item) => item.status, {
      columnLabel: "Status",
      renderCell: ({ label, item }) => (
        <Indent>
          <HeadAgreementStatusCodeChip
            statusCode={item.status}
            labelToLowerCase
          />
        </Indent>
      ),
      minWidth: "90px",
      disableGridCell: true,
    }),
    agreementNumber: createColumnConfig((item) => item.agreementNumber, {
      columnLabel: "Number",
      renderCell: ({ label, item }) => (
        <Indent>
          <Link
            to={routeFactory.productAndPrice.customer.customerHeadAgreement({
              customerId: item.customerId,
              headAgreementId: item.headAgreementId,
            })}
          >
            {item.agreementNumber}
          </Link>
        </Indent>
      ),
      minWidth: "60px",
      disableGridCell: true,
    }),
    name: createColumnConfig((item) => item.name, {
      columnLabel: "Name",
      renderCell: ({ label, item }) => (
        <Indent>
          <Text>{item.name}</Text>
        </Indent>
      ),
      minWidth: "200px",
      disableGridCell: true,
    }),
    validity: createColumnConfig((item) => item.validity, {
      columnLabel: "Validity",
      renderCell: ({ label, item }) => (
        <Indent>
          <Text>
            {formatDateStringRangeWithSwedishTimeZone({
              dateStringFrom: item.validity.from,
              dateStringTo: item.validity.to,
            })}
          </Text>
        </Indent>
      ),
      minWidth: "190px",
      disableGridCell: true,
    }),
  },

  columnOrder: ["status", "agreementNumber", "name", "validity"],
};
