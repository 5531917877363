import {
  SearchFilterSelectorContext,
  SearchFilterSelectorContextType,
} from "@/features/search-filter/context/searchFilterSelectorContext";
import { useFilterWorkspace } from "@/features/search-filter/hooks/UseFilterWorkspace";
import { useQuery } from "@apollo/client";
import { Global, css } from "@emotion/react";
import { ErrorScreen, LoadingScreen } from "@stenajs-webui/panels";
import { gql } from "apollo-boost";
import * as React from "react";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../../../../common/components/page-layout/PageLayout";
import { useCustomer } from "../../../customers/common/customer-head-agreement-header/UseCustomer";
import { useNegotiationYears } from "../../../customers/common/negotiation-year/NegotiationYearGate";
import { CustomerViewInfoPairBoxes } from "../../../info-boxes/CustomerViewInfoPairBoxes";
import { useRouteAgreementsRatesByFilterQuery } from "../hooks/UseRouteAgreementsRatesByFilter";
import {
  bulkEditRatesActions,
  bulkEditRatesStandardTableActions,
} from "../redux/reducer";
import { BulkEditRatesHeader } from "./BulkEditRatesHeader";
import { BulkEditRatesTableWrapper } from "./BulkEditRatesTableWrapper";
import { transformFilterModelToFilterInput } from "./filter/transformFilterModelToFilterInput";

import { bulkEditRatesStatBoxesActions } from "./stat-boxes/redux/reducer";
import {
  CustomerHeaderCustomersByIdFragment,
  CustomerIndexQuery,
  CustomerIndexQueryVariables,
} from "@/gql/graphql";

const query = gql`
  query CustomerIndex($customerId: ID!, $headAgreementId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          custIndex
          name
          custNo
          invoiceCurrency {
            id
            code
          }
          hasRebateAgreements
          salesRegion {
            id
            name
          }
          statisticalGrouping {
            id
          }
          seller {
            id
            name
          }
          custStatus {
            id
            name
          }
          isParent
          parent {
            id
          }
          childCustomers {
            id
            name
          }
        }
      }

      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          statusCode
          name
          headAgreementNumber
          breadCrumbName
          hasSomeRouteAgreementsWithMultiLeg
          statusCode
          rowVersion
          valid {
            start {
              isoString
            }
            end {
              isoString
            }
          }
          writeHistory {
            createdAt {
              isoString
            }
            createdBy {
              id
              name
            }
            modifiedAt {
              isoString
            }
            modifiedBy {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface FilterLoaderProps {
  negotiationYear: number;
  customer: CustomerHeaderCustomersByIdFragment | null | undefined;
  headAgreement:
    | CustomerIndexQuery["productPrice"]["headAgreement"]["byId"]
    | null
    | undefined;
}

const BultEditRatesFilterLoader = ({
  negotiationYear,
  customer,
  headAgreement,
}: FilterLoaderProps) => {
  const dispatch = useDispatch();

  const { committedState } = useContext(
    SearchFilterSelectorContext
  ) as SearchFilterSelectorContextType;

  const { commit } = useFilterWorkspace();

  useEffect(() => {
    return () => {
      dispatch(bulkEditRatesStandardTableActions.clearSelection());
      dispatch(bulkEditRatesActions.revertAll());
      dispatch(bulkEditRatesStatBoxesActions.clearAll());
      commit();
    };
  }, [commit, dispatch]);

  const { routeAgreementRates, loading, error, refetch } =
    useRouteAgreementsRatesByFilterQuery(
      committedState
        ? transformFilterModelToFilterInput(
            headAgreement?.id ?? "",
            committedState
          )
        : null,
      customer?.id ?? "",
      negotiationYear
    );

  return (
    <>
      <Global
        styles={css`
          body {
            display: table;
          }
        `}
      />
      <Helmet title="Edit rates - Customer - FPP" />
      <BulkEditRatesHeader
        custIndex={customer?.custIndex ?? " "}
        routeAgreementRates={routeAgreementRates}
        refetch={refetch}
      />
      <PageLayout>
        {customer && headAgreement && (
          <CustomerViewInfoPairBoxes
            customer={customer}
            headAgreement={headAgreement}
            headAgreementId={headAgreement.id}
            switchButtonDisabled={true}
            justifyContentRow={"flex-start"}
            customerPopoverEnabled
          />
        )}

        <BulkEditRatesTableWrapper
          loading={loading}
          error={error}
          routeAgreementRates={routeAgreementRates}
        />
      </PageLayout>
    </>
  );
};

export const BulkEditRatesFetcher: React.FC = () => {
  const { headAgreementId, customerId } = useParams() as {
    headAgreementId: string;
    customerId: string;
  };

  const { data, loading: loadingCustomer } = useQuery<
    CustomerIndexQuery,
    CustomerIndexQueryVariables
  >(query, {
    variables: {
      customerId,
      headAgreementId,
    },
  });

  const customerIndex = data?.productPrice.customers.byId?.custIndex;
  const headAgreement = data?.productPrice.headAgreement.byId;

  const { defaultYear } = useNegotiationYears();
  const { customer } = useCustomer(customerId, defaultYear);

  if (!headAgreementId) {
    return <ErrorScreen text={"Missing headAgreementId."} />;
  }
  if (!customerId) {
    return <ErrorScreen text={"Missing customerId."} />;
  }

  if (loadingCustomer || !customerIndex) {
    return <LoadingScreen />;
  }

  if (!headAgreement) {
    return <ErrorScreen text={"No head agreement found for that id"} />;
  }

  const validFrom = headAgreement.valid.start?.isoString;

  const negotiationYear = validFrom
    ? new Date(validFrom).getUTCFullYear()
    : new Date().getUTCFullYear();

  return (
    <BultEditRatesFilterLoader
      negotiationYear={negotiationYear}
      customer={customer}
      headAgreement={headAgreement}
    />
  );
};
