import { Box } from "@stenajs-webui/core";
import { FlatButton, stenaInfoCircle } from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";
import { HeadAgreementBox } from "../../types";
import { InfoBoxItem } from "../../InfoBoxItem";

interface Props {
  headAgreement: HeadAgreementBox;
  actionMenuOpen: boolean;
  openActionMenu: () => void;
  closeActionMenu: () => void;
}

export const HeadAgreementInfoPairPopover: React.FC<Props> = ({
  headAgreement,
  actionMenuOpen,
  openActionMenu,
  closeActionMenu,
}) => {
  const headNameUpperCase = headAgreement.name?.toUpperCase() || "";

  return (
    <Popover
      placement={"right"}
      animation={false}
      visible={actionMenuOpen}
      disablePadding
      onClickOutside={closeActionMenu}
      appendTo={document.body}
      content={
        <Box indent={2} spacing={1}>
          <InfoBoxItem
            leftContent={"Name"}
            rightContent={headNameUpperCase}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created"}
            rightContent={
              formatDateStringWithSwedishTimeZone({
                dateString: headAgreement.writeHistory?.createdAt?.isoString,
                options: {
                  dateStyle: "short",
                  timeStyle: "short",
                },
              }) ?? ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created by"}
            rightContent={headAgreement.writeHistory?.createdBy.name}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified"}
            rightContent={
              (headAgreement.writeHistory?.modifiedAt?.isoString &&
                formatDateStringWithSwedishTimeZone({
                  dateString: headAgreement.writeHistory?.modifiedAt?.isoString,
                  options: {
                    dateStyle: "short",
                    timeStyle: "short",
                  },
                })) ??
              ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified by"}
            rightContent={headAgreement.writeHistory?.modifiedBy?.name ?? ""}
            popoverEnabled
          />
        </Box>
      }
    >
      <Box width={16}>
        <FlatButton
          leftIcon={stenaInfoCircle}
          onClick={actionMenuOpen ? closeActionMenu : openActionMenu}
        />
      </Box>
    </Popover>
  );
};
