import { CustomerDetailsFragment } from "@/gql/graphql";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  stenaBusinessAgreement,
  stenaExternalLink,
} from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import * as React from "react";
import {
  topAgreementsConfig,
  TopAgreementsTableRow,
} from "./TopAgreementsTableConfig";
import { useNavigate } from "react-router-dom";
import { routeFactory } from "@/RouteFactory";
import { CustomerHeadAgreementScreenParams } from "@/features/product-and-price/head-agreement/details/components/CustomerHeadAgreementScreen";

interface Props {
  customerId: string;
  headAgreements: CustomerDetailsFragment["topHeadAgreements"];
}

export const TopHeadAgreementsInfoBox: React.FC<Props> = ({
  customerId,
  headAgreements,
}) => {
  const navigate = useNavigate();
  const navigateHeadAgreementParams: CustomerHeadAgreementScreenParams = {
    customerId,
    headAgreementId: headAgreements.length
      ? headAgreements[0].customer.preselectedHeadAgreement?.id
      : "",
  };

  const transformHeadAgreementsToTableRow = (
    headAgreements: CustomerDetailsFragment["topHeadAgreements"]
  ): Array<TopAgreementsTableRow> => {
    return headAgreements.map((agreement) => {
      return {
        agreementNumber: agreement.headAgreementNumber,
        customerId: agreement.customer.id,
        headAgreementId: agreement.id,
        name: agreement.name,
        status: agreement.statusCode,
        validity: {
          from: agreement.valid.start?.isoString,
          to: agreement.valid.end?.isoString,
        },
      };
    });
  };

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Header agreements"}
        leftIcon={stenaBusinessAgreement}
        contentRight={
          <FlatButton
            leftIcon={stenaExternalLink}
            onClick={() =>
              navigate(
                headAgreements.length
                  ? routeFactory.productAndPrice.customer.customerHeadAgreement(
                      navigateHeadAgreementParams
                    )
                  : routeFactory.productAndPrice.customer.customerNoHeadAgreement(
                      { customerId }
                    )
              )
            }
            title={"Go to header agreement"}
          />
        }
      />
      <CardBody overflow={"auto"}>
        <StandardTable
          items={transformHeadAgreementsToTableRow(headAgreements)}
          config={topAgreementsConfig}
          loading={false}
          noItemsLabel={"No header agreements found."}
        />
      </CardBody>
    </Card>
  );
};
