import { Window } from "@stenajs-webui/modal";
import * as React from "react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Indent,
  Row,
  SeparatorLine,
  Space,
  Spacing,
  Text,
  useBoolean,
} from "@stenajs-webui/core";
import {
  FlatButton,
  Icon,
  Label,
  PrimaryButton,
  stenaExclamationTriangle,
} from "@stenajs-webui/elements";
import { useDeleteHeadAgreementMutation } from "../hooks/UseDeleteHeadAgreementMutation";
import { asyncDelay } from "../../../../../common/async-util/AsyncDelay";
import { cssColor } from "@stenajs-webui/theme";
import { HeadAgreementStatusCodeChip } from "@/common/components/chips/HeadAgreementStatusCodeChip";
import { ValidationErrorMessage } from "@/common/components/validation-error-message/ValidationErrorMessage";
import { formatGQLDateRange } from "@/common/formatters/DateFormatter";
import { routeFactory } from "@/RouteFactory";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import {
  HeadAgreementInfoRowSectionFragment,
  HeadAgreementStatusCode,
} from "@/gql/graphql";

interface Props {
  headAgreement: HeadAgreementInfoRowSectionFragment;
  onRequestClose: () => void;
}

export const DeleteHeadAgreementModal: React.FC<Props> = ({
  headAgreement,
  onRequestClose,
}) => {
  const navigate = useNavigate();
  const [localError, setLocalError] = useState<Error | undefined>();
  const [success, setSuccess] = useBoolean(false);

  const { loading, deleteHeadAgreement } = useDeleteHeadAgreementMutation();

  const canDelete =
    headAgreement.statusCode === HeadAgreementStatusCode.Pending;

  const onClickDelete = useCallback(async () => {
    if (canDelete) {
      setLocalError(undefined);
      try {
        const r = await deleteHeadAgreement(
          headAgreement.id,
          headAgreement.rowVersion
        );
        const deleteResult =
          r.data?.productPrice.headAgreement.deleteHeadAgreement;
        if (!deleteResult || (r.errors && r.errors.length)) {
          setLocalError(r.errors?.[0] ?? new Error("Unknown error"));
        } else if (deleteResult && "errors" in deleteResult) {
          setLocalError(
            new Error(
              deleteResult.errors.map((error) => error.message).join("\n")
            )
          );
        } else {
          setSuccess();
          await asyncDelay(1000);
          const nextHeadAgreementId =
            deleteResult.customer.preselectedHeadAgreement?.id;
          const customerId = deleteResult.customer.id;

          if (nextHeadAgreementId) {
            navigate(
              routeFactory.productAndPrice.customer.customerHeadAgreement({
                headAgreementId: nextHeadAgreementId,
                customerId,
              })
            );
          } else {
            navigate(
              routeFactory.productAndPrice.customer.customer({
                customerId,
              })
            );
          }
        }
      } catch (e) {
        setLocalError(e);
      }
    }
  }, [headAgreement, deleteHeadAgreement, navigate, setSuccess, canDelete]);

  return (
    <Window
      headerText={`Delete header agreement (${headAgreement.headAgreementNumber})?`}
      draggable
      isOpen
      width={"600px"}
      onRequestClose={onRequestClose}
      indent={2}
    >
      {!canDelete && (
        <Box spacing={1}>
          <Row
            border={`1px solid ${cssColor("--lhds-color-ui-300")}`}
            spacing
            indent
          >
            <Icon
              icon={stenaExclamationTriangle}
              color={cssColor("--lhds-color-blue-500")}
            />
            <Indent />
            <Text>
              Header agreements can only be deleted when they have status
              PENDING.
            </Text>
          </Row>
        </Box>
      )}

      <Spacing num={3}>
        <Label textWidth={"58px"} text={"Status"} row>
          <HeadAgreementStatusCodeChip statusCode={headAgreement.statusCode} />
        </Label>
        <SeparatorLine />
        <Space />

        <Label textWidth={"58px"} text={"Name"} row>
          <Text>{headAgreement.name}</Text>
        </Label>
        <SeparatorLine />
        <Space />

        <Label textWidth={"58px"} text={"Number"} row>
          <Text>{headAgreement.headAgreementNumber}</Text>
        </Label>
        <SeparatorLine />
        <Space />

        <Label textWidth={"58px"} text={"Validity"} row>
          <Text>{formatGQLDateRange(headAgreement.valid)}</Text>
        </Label>
      </Spacing>
      <Row spacing={2} justifyContent={"flex-end"} alignItems={"center"}>
        {localError && <ValidationErrorMessage error={localError} />}
        <FlatButton
          label={"No"}
          onClick={onRequestClose}
          disabled={loading || success}
        />
        <Indent />
        <PrimaryButton
          label={"Yes, delete it"}
          onClick={onClickDelete}
          loading={loading}
          success={success}
          disabled={!canDelete}
          data-testid={testIdConstants.deleteHeaderAgreementModalYesButton}
        />
      </Row>
    </Window>
  );
};
