import { NumericTextInput } from "@/common/components/numeric-text-input/NumericTextInput";
import { FormulaTypeSelectQuery } from "@/gql/graphql";
import { Box, Spacing } from "@stenajs-webui/core";
import { Label } from "@stenajs-webui/elements";
import { ErrorScreen } from "@stenajs-webui/panels";
import { startCase } from "lodash";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { articleFormActions } from "../actions";
import { articleFormSelectors } from "../selectors";

type FormulaField = keyof Omit<
  FormulaTypeSelectQuery["productPrice"]["formula"]["all"]["0"]["includes"],
  "__typename"
>;

interface Props {
  articleTypeId: string;
  articleId: string;
  field: FormulaField;
}

export const FormulaValueInput: React.FC<Props> = ({
  articleTypeId,
  field,
  articleId,
}) => {
  const dispatch = useDispatch();

  const { articles } = useSelector(articleFormSelectors.getState);

  const formulaData = useMemo(
    () => articles[articleId]?.formulaData,
    [articleId, articles]
  );

  const onChangeFormulaData = useCallback(
    (value: string) => {
      const action = articleFormActions.setFormulaDataFields(articleId, {
        [field]: value,
      });
      dispatch(action);
    },
    [dispatch, articleId, field]
  );

  if (!formulaData) {
    return (
      <Spacing num={6}>
        <ErrorScreen text={"Can not find article."} />
      </Spacing>
    );
  }

  return (
    <Box spacing>
      <Label text={startCase(field)} width={"200px"}>
        <NumericTextInput
          value={formulaData[field] ?? ""}
          onValueChange={onChangeFormulaData}
          maxDecimals={0}
        />
      </Label>
    </Box>
  );
};
