import { Box } from "@stenajs-webui/core";
import { FlatButton, stenaInfoCircle } from "@stenajs-webui/elements";
import { Popover } from "@stenajs-webui/tooltip";
import { InfoBoxItem } from "../InfoBoxItem";
import { RouteAgreementBox } from "../types";
import { useSelector } from "react-redux";
import { StoreState } from "@/config/redux/RootReducer";
import { formatDateStringWithSwedishTimeZone } from "@/common/dates/formatters";

interface Props {
  routeAgreement: RouteAgreementBox;
  actionMenuOpen: boolean;
  openActionMenu: () => void;
  closeActionMenu: () => void;
}

const selector = (state: StoreState) =>
  state.routeAgreementDetails.workspace.routes.currencyId;

export const RouteAgreementInfoPairPopover: React.FC<Props> = ({
  routeAgreement,
  actionMenuOpen,
  openActionMenu,
  closeActionMenu,
}) => {
  const currencyId = useSelector(selector);
  const actionMenuClicked = (shouldOpenMenu: boolean) => {
    if (shouldOpenMenu) {
      openActionMenu();
    } else {
      closeActionMenu();
    }
  };
  return (
    <Popover
      placement={"right"}
      animation={false}
      visible={actionMenuOpen}
      disablePadding
      onClickOutside={closeActionMenu}
      appendTo={document.body}
      content={
        <Box indent={2} spacing={1}>
          <InfoBoxItem
            leftContent={"Name"}
            rightContent={routeAgreement.name}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Agr. cur."}
            rightContent={(currencyId && routeAgreement.currency.code) ?? ""}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Invoice curr."}
            rightContent={currencyId ?? routeAgreement.currency.code}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"No."}
            rightContent={routeAgreement.agreementNumber}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created"}
            rightContent={
              formatDateStringWithSwedishTimeZone({
                dateString: routeAgreement.writeHistory?.createdAt?.isoString,
                options: {
                  dateStyle: "short",
                  timeStyle: "short",
                },
              }) ?? ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Created by"}
            rightContent={routeAgreement.writeHistory?.createdBy?.name || ""}
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified"}
            rightContent={
              (routeAgreement.writeHistory?.modifiedAt?.isoString &&
                formatDateStringWithSwedishTimeZone({
                  dateString:
                    routeAgreement.writeHistory?.modifiedAt?.isoString,
                  options: {
                    dateStyle: "short",
                    timeStyle: "short",
                  },
                })) ??
              ""
            }
            popoverEnabled
          />
          <InfoBoxItem
            leftContent={"Modified by"}
            rightContent={routeAgreement.writeHistory?.modifiedBy?.name || ""}
            popoverEnabled
          />
        </Box>
      }
    >
      <Box width={16}>
        <FlatButton
          leftIcon={stenaInfoCircle}
          onClick={() => {
            !actionMenuOpen
              ? actionMenuClicked(true)
              : actionMenuClicked(false);
          }}
        />
      </Box>
    </Popover>
  );
};
