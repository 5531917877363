import * as React from "react";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../../../../RouteFactory";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { ErrorScreen } from "@stenajs-webui/panels";
import { PageHeaderItem } from "../../../../../common/components/page-header/PageHeaderItem";
import { HeadAgreementModelLoader } from "./HeadAgreementModelLoader";
import { BreadCrumbs } from "@stenajs-webui/elements";
import { PageLayout } from "@/common/components/page-layout/PageLayout";
import {
  EditHeadAgreementCustomerQuery,
  EditHeadAgreementCustomerQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
}

const query = gql`
  query EditHeadAgreementCustomer($customerId: ID!, $headAgreementId: ID!) {
    productPrice {
      customers {
        byId(id: $customerId) {
          id
          name
          breadCrumbName
          custIndex
          custNo
        }
      }
      headAgreement {
        byId(headAgreementId: $headAgreementId) {
          id
          breadCrumbName
        }
      }
    }
  }
`;

export const EditHeadAgreement: React.FC<Props> = ({
  customerId,
  headAgreementId,
}) => {
  const { data, loading, error } = useQuery<
    EditHeadAgreementCustomerQuery,
    EditHeadAgreementCustomerQueryVariables
  >(query, {
    variables: {
      customerId,
      headAgreementId,
    },
  });

  const customer = data?.productPrice.customers.byId;

  return (
    <>
      <PageHeader
        title={"Edit header agreement"}
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"Negotiation plan"}
              path={routeFactory.productAndPrice.customer.customerList()}
            />
            <Crumb
              label={customer?.breadCrumbName ?? ""}
              path={
                headAgreementId
                  ? routeFactory.productAndPrice.customer.customerHeadAgreement(
                      {
                        customerId,
                        headAgreementId,
                      }
                    )
                  : routeFactory.productAndPrice.customer.customer({
                      customerId,
                    })
              }
            />
            <Crumb
              label={"edit"}
              path={routeFactory.productAndPrice.headAgreement.editHeadAgreement(
                {
                  customerId,
                  headAgreementId,
                }
              )}
              isLast
            />
          </BreadCrumbs>
        }
      >
        <PageHeaderItem label={"Cust. no."} value={customer?.custNo} />
        <PageHeaderItem label={"Cust. name"} value={customer?.name} />
        <PageHeaderItem label={"Cust. index"} value={customer?.custIndex} />
      </PageHeader>

      {error && !loading && <ErrorScreen text={"No such customer."} />}
      {customer && (
        <PageLayout>
          <HeadAgreementModelLoader
            customerId={customerId}
            headAgreementId={headAgreementId}
          />
        </PageLayout>
      )}
    </>
  );
};
