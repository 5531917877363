import { HeadAgreementStatusCode } from "@/gql/graphql";

export const canDeleteAgreement = (status: HeadAgreementStatusCode) => {
  return status === HeadAgreementStatusCode.Pending;
};

export const canChangeHeadAgreementStatusToActive = (
  status: HeadAgreementStatusCode
) => {
  return (
    status === HeadAgreementStatusCode.Offered ||
    status === HeadAgreementStatusCode.Pending
  );
};

export const canChangeRouteAgreementStatusToActive = (
  parentStatus: HeadAgreementStatusCode,
  status: HeadAgreementStatusCode
) => {
  return (
    status !== HeadAgreementStatusCode.Void &&
    parentStatus === HeadAgreementStatusCode.Active &&
    (status === HeadAgreementStatusCode.Offered ||
      status === HeadAgreementStatusCode.Pending)
  );
};

export const canChangeStatusToPending = (
  validFromDate: Date,
  status: HeadAgreementStatusCode
) => {
  return (
    status !== HeadAgreementStatusCode.Void &&
    (status === HeadAgreementStatusCode.Active ||
      status === HeadAgreementStatusCode.Offered) &&
    validFromDate > new Date()
  );
};

export const canChangeStatusToVoid = (
  statusCode: HeadAgreementStatusCode,
  validStart: string | null
) => {
  if (!validStart) {
    return false;
  }

  if (statusCode === HeadAgreementStatusCode.Active) {
    const today = new Date().toISOString().slice(0, 10);
    return !(validStart && validStart > today);
  }
  return false;
};
