import { useQuery } from "@apollo/client";
import {
  BreadCrumbs,
  ResultListBanner,
  TabMenu,
  stenaBusinessAgreement,
  useResultListBannerState,
} from "@stenajs-webui/elements";
import * as React from "react";
import { Crumb } from "../../../../common/components/bread-crumbs/Crumb";
import { PageHeader } from "../../../../common/components/page-header/PageHeader";
import { Tab } from "../../../../common/components/tabs/Tab";
import { TabsRouting } from "../../../../common/navigation/agreement-tabs/AgreementTabsRouting";
import { routeFactory } from "../../../../RouteFactory";
import { useCustomer } from "../../customers/common/customer-head-agreement-header/UseCustomer";
import { useNegotiationYears } from "../../customers/common/negotiation-year/NegotiationYearGate";
import { routeAgreementQuery } from "../../route-agreement/details/components/RouteAgreementDetailsHeader";
import { RouteAgreementSaveButton } from "../../route-agreement/details/components/RouteAgreementSaveButton";
import { routeAgreementCanBeEdited } from "../../route-agreement/util/RouteAgreementCalculator";
import {
  RouteAgreementDetailsHeaderQuery,
  RouteAgreementDetailsHeaderQueryVariables,
} from "@/gql/graphql";

interface Props {
  customerId: string;
  headAgreementId: string;
  multiLegAgreementId: string;
  routeAgreementId: string;
  multiLegCode: string;
  multiLegNumber: number | null;
}

export const MultiLegRouteAgreementDetailsHeader: React.FC<Props> = (props) => {
  const {
    customerId,
    multiLegAgreementId,
    routeAgreementId,
    headAgreementId,
    multiLegCode,
    multiLegNumber,
  } = props;
  const bannerHook = useResultListBannerState(undefined);
  const { loading, data } = useQuery<
    RouteAgreementDetailsHeaderQuery,
    RouteAgreementDetailsHeaderQueryVariables
  >(routeAgreementQuery, {
    variables: {
      routeAgreementId,
    },
  });
  const { defaultYear } = useNegotiationYears();

  const { customer } = useCustomer(customerId, defaultYear);

  const headAgreement = data?.productPrice.routeAgreement.byId?.headAgreement;
  const routeAgreement = data?.productPrice.routeAgreement.byId;

  const path =
    routeFactory.productAndPrice.multiLegAgreement.multiLegRouteAgreementDetails(
      {
        customerId: props.customerId,
        headAgreementId: props.headAgreementId,
        routeAgreementId: props.routeAgreementId,
        multiLegAgreementId: multiLegAgreementId,
      }
    );

  return (
    <>
      <PageHeader
        title={"Multi leg route agreement"}
        loading={loading}
        icon={stenaBusinessAgreement}
        contentRight={
          routeAgreement && (
            <>
              <RouteAgreementSaveButton
                routeAgreementId={routeAgreementId}
                rowVersion={routeAgreement.rowVersion}
                editableRouteAgreement={routeAgreementCanBeEdited(
                  routeAgreement
                )}
                bannerHook={bannerHook}
                isMultiLeg
              />
            </>
          )
        }
        contentUnder={
          bannerHook.bannerState && (
            <ResultListBanner
              bannerState={bannerHook.bannerState}
              variant={"error"}
            />
          )
        }
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              label={"Negotiation plan"}
              path={routeFactory.productAndPrice.customer.customerList()}
            />
            {customer && (
              <Crumb
                label={customer?.breadCrumbName ?? ""}
                path={routeFactory.productAndPrice.customer.customerHeadAgreement(
                  {
                    customerId,
                    headAgreementId,
                  }
                )}
              />
            )}
            {headAgreement && (
              <Crumb
                label={
                  multiLegNumber
                    ? `${multiLegCode} (${multiLegNumber})`
                    : `${multiLegCode}`
                }
                path={routeFactory.productAndPrice.multiLegAgreement.multiLegAgreementDetails(
                  {
                    customerId,
                    headAgreementId: headAgreement.id,
                    multiLegAgreementId: multiLegAgreementId,
                  }
                )}
              />
            )}
            {headAgreement && routeAgreement && (
              <Crumb
                label={String(routeAgreement.agreementNumber)}
                path={routeFactory.productAndPrice.multiLegAgreement.multiLegRouteAgreementDetails(
                  {
                    customerId,
                    headAgreementId: headAgreement.id,
                    multiLegAgreementId: multiLegAgreementId,
                    routeAgreementId,
                    tab: "rates",
                  }
                )}
              />
            )}
          </BreadCrumbs>
        }
        tabs={
          <TabMenu>
            <Tab
              label={TabsRouting.Rates}
              path={path + TabsRouting.RatesPath}
            />
            <Tab
              label={TabsRouting.Routes}
              path={path + TabsRouting.RoutesPath}
            />
            <Tab
              label={TabsRouting.Articles}
              path={path + TabsRouting.ArticlesPath}
              hasBadge={routeAgreement?.hasArticles}
            />
            <Tab
              label={TabsRouting.Notes}
              path={path + TabsRouting.NotesPath}
            />
            <Tab
              label={TabsRouting.Conditions}
              path={path + TabsRouting.ConditionsPath}
              hasBadge={routeAgreement?.hasConditions}
            />
            <Tab
              label={TabsRouting.Matrix}
              path={path + TabsRouting.MatrixPath}
              hasBadge={routeAgreement?.hasMatrix}
            />
          </TabMenu>
        }
      />
    </>
  );
};
