import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { CustomerDetailsFragment } from "@/gql/graphql";
import { routeFactory } from "@/RouteFactory";
import { Space } from "@stenajs-webui/core";
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  ResultListBanner,
  stenaDetails,
  stenaExternalLink,
  Tab,
  TabMenu,
} from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import { uniq } from "lodash";
import * as React from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { documentsConfig, DocumentTableRow } from "./DocumentsTableConfig";
import {
  rebateDocumentsConfig,
  RebateDocumentTableRow,
} from "./RebateDocumentsTableConfig";

enum SelectedTab {
  "agreementDocuments",
  "rebateDocuments",
}

interface Props {
  customerNumber: string;
  agreementDocuments: CustomerDetailsFragment["topAgreementDocuments"];
  rebateAgreementDocuments: CustomerDetailsFragment["topRebateAgreementDocuments"];
}

export const DocumentsInfoBox: React.FC<Props> = ({
  customerNumber,
  agreementDocuments,
  rebateAgreementDocuments,
}) => {
  const navigate = useNavigate();
  const [downloadErrorAgreements, setDownloadErrorAgreements] =
    useState<FreightPricingPortalError>();
  const [downloadErrorRebates, setDownloadErrorRebates] =
    useState<FreightPricingPortalError>();
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.agreementDocuments
  );

  const distinctAgreementErrorMessages = uniq(
    downloadErrorAgreements?.errors.map((error) => error.message)
  );

  const distinctRebateErrorMessages = uniq(
    downloadErrorAgreements?.errors.map((error) => error.message)
  );

  const transformAgreementDocumentsToTableRow = (
    agreementDocuments: CustomerDetailsFragment["topAgreementDocuments"]
  ): Array<DocumentTableRow> => {
    return agreementDocuments.map((document) => {
      return {
        agreementDocumentType: document.agreementDocumentType,
        customerNumber: document.customerNumber,
        fileName: document.fileName,
        id: document.id,
        negotiationYear: document.negotiationYear,
        agreementId: document.agreementId,
        agreementValidFromDate: document.agreementValidFromDate,
        agreementValidToDate: document.agreementValidToDate,
        writeHistory: document.writeHistory,
      };
    });
  };

  const transformRebateAgreementDocumentsToTableRow = (
    agreementDocuments: CustomerDetailsFragment["topRebateAgreementDocuments"]
  ): Array<RebateDocumentTableRow> => {
    return agreementDocuments.map((document) => {
      return {
        customerNumber: document.customerNumber,
        fileName: document.fileName,
        id: document.id,
        negotiationYear: document.negotiationYear,
        rebateAgreementId: document.rebateAgreementId,
        rebateAgreementValidFromDate: document.rebateAgreementValidFromDate,
        rebateAgreementValidToDate: document.rebateAgreementValidToDate,
        writeHistory: document.writeHistory,
      };
    });
  };

  const agreementDocumentConfig = useMemo(() => {
    return documentsConfig(downloadErrorAgreements, setDownloadErrorAgreements);
  }, [downloadErrorAgreements, setDownloadErrorAgreements]);

  const rebateAgreementDocumentConfig = useMemo(() => {
    return rebateDocumentsConfig(downloadErrorRebates, setDownloadErrorRebates);
  }, [downloadErrorRebates, setDownloadErrorRebates]);

  return (
    <Card>
      <CardHeader
        variant={"compact"}
        text={"Documents"}
        leftIcon={stenaDetails}
        contentRight={
          <FlatButton
            leftIcon={stenaExternalLink}
            onClick={() =>
              selectedTab === SelectedTab.agreementDocuments
                ? navigate(
                    routeFactory.productAndPrice.headAgreement.agreementDocuments(
                      { customerNo: customerNumber }
                    )
                  )
                : navigate(
                    routeFactory.productAndPrice.headAgreement.rebateAgreementDocuments(
                      { customerNo: customerNumber }
                    )
                  )
            }
            title={"Go to documents"}
          />
        }
      />

      <CardBody overflow={"auto"}>
        <TabMenu>
          <Tab
            label={"Agreements"}
            onClick={() => setSelectedTab(SelectedTab.agreementDocuments)}
            selected={selectedTab === SelectedTab.agreementDocuments}
          />
          <Tab
            label={"Rebate"}
            onClick={() => setSelectedTab(SelectedTab.rebateDocuments)}
            selected={selectedTab === SelectedTab.rebateDocuments}
          />
        </TabMenu>
        <Space />
        {downloadErrorAgreements &&
          selectedTab === SelectedTab.agreementDocuments && (
            <>
              <ResultListBanner
                bannerState={{
                  headerText: "Unable to download document",
                  items: distinctAgreementErrorMessages.map((error) => ({
                    text: error,
                  })),
                }}
                variant={"error"}
              />
              <Space />
            </>
          )}
        {downloadErrorRebates &&
          selectedTab === SelectedTab.rebateDocuments && (
            <>
              <ResultListBanner
                bannerState={{
                  headerText: "Unable to download document",
                  items: distinctRebateErrorMessages.map((error) => ({
                    text: error,
                  })),
                }}
                variant={"error"}
              />
              <Space />
            </>
          )}
        {selectedTab === SelectedTab.agreementDocuments ? (
          <StandardTable
            items={transformAgreementDocumentsToTableRow(agreementDocuments)}
            config={agreementDocumentConfig}
            loading={false}
            noItemsLabel={"No documents found."}
          />
        ) : (
          <StandardTable
            items={transformRebateAgreementDocumentsToTableRow(
              rebateAgreementDocuments
            )}
            config={rebateAgreementDocumentConfig}
            loading={false}
            noItemsLabel={"No rebate documents found."}
          />
        )}
      </CardBody>
    </Card>
  );
};
