import {
  CustomerDetailsViewQuery,
  CustomerDetailsViewQueryVariables,
} from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";

const query = gql`
  fragment CustomerDetails on Customer {
    id
    name
    breadCrumbName
    custIndex
    custNo
    crmLink
    isParent
    custStatus {
      id
      name
    }
    parent {
      id
      preselectedHeadAgreement {
        id
      }
    }
    childCustomers {
      id
    }
    statisticalGrouping {
      id
    }
    invoiceCurrency {
      id
      code
    }
    seller {
      id
      name
    }
    salesRegion {
      id
      code
      name
    }
    isStandardCustomer
    hasRebateAgreements
    writeHistory {
      createdAt {
        isoString
      }
      createdBy {
        id
        name
      }
      modifiedAt {
        isoString
      }
      modifiedBy {
        id
        name
      }
    }
    topAgreementDocuments(numberOfDocuments: $numberOfDocuments) {
      id
      agreementId
      customerNumber
      fileName
      agreementValidFromDate
      agreementValidToDate
      agreementDocumentType
      negotiationYear
      writeHistory {
        createdAt {
          isoString
        }
        createdBy {
          id
          name
        }
      }
    }
    topRebateAgreementDocuments(numberOfDocuments: $numberOfDocuments) {
      id
      rebateAgreementId
      customerNumber
      fileName
      rebateAgreementValidFromDate
      rebateAgreementValidToDate
      negotiationYear
      writeHistory {
        createdAt {
          isoString
        }
        createdBy {
          id
          name
        }
      }
    }
    topHeadAgreements(numberOfAgreements: $numberOfAgreements) {
      statusCode
      headAgreementNumber
      name
      customer {
        id
        preselectedHeadAgreement {
          id
        }
      }
      id
      valid {
        start {
          isoString
        }
        end {
          isoString
        }
      }
    }
    customerThreeYearsStatistics(negotiationYear: $negotiationYear) {
      actualRate
      customerNumber
      diffPreviousYear
      expectedVolume
      id
      last12MonthsVolume
      negotiationStatus
      pricePerMeter
      targetRate
      year
    }
  }

  query CustomerDetailsView(
    $customerId: ID!
    $numberOfAgreements: Int!
    $numberOfDocuments: Int!
    $negotiationYear: Int!
  ) {
    productPrice {
      customers {
        byId(id: $customerId) {
          ...CustomerDetails
        }
      }
    }
  }
`;

export const useCustomerDetailsView = (
  customerId: string,
  numberOfAgreements: number,
  numberOfDocuments: number,
  negotiationYear: number
) => {
  const { loading, data } = useQuery<
    CustomerDetailsViewQuery,
    CustomerDetailsViewQueryVariables
  >(query, {
    variables: {
      customerId,
      numberOfAgreements,
      numberOfDocuments,
      negotiationYear,
    },
  });
  return { loading, customer: data?.productPrice.customers.byId };
};
