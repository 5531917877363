import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";

import { FetchResult } from "apollo-boost";
import { compact } from "lodash";
import { CheckBoxSelectionState } from "../redux/reducers";
import { WorkspaceState } from "@/features/search-filter/redux/reducer";
import {
  CalculateThresholdInput,
  CalculateThresholdMutation,
  CreateInvoicesInput,
  CreateInvoicesMutation,
  UpdateBookingHandlingStatusInput,
  UpdateHandleBookingStatusMutation,
} from "@/gql/graphql";

export const handleBookingStatus = async (
  selectedState: CheckBoxSelectionState,
  selectedBookingStatus: {
    value: BookingStatusOptions;
  },
  setBookingStatus: (
    input: UpdateBookingHandlingStatusInput[]
  ) => Promise<FetchResult<UpdateHandleBookingStatusMutation>>,
  setLocalErrors: React.Dispatch<
    React.SetStateAction<Array<FreightPricingPortalError>>
  >,
  setModalErrors: React.Dispatch<
    React.SetStateAction<Array<FreightPricingPortalError>>
  >,
  setExcludeReasonModalOpen: (value: React.SetStateAction<boolean>) => void,
  deselectAllBookings: () => void,
  excludeReason?: string
) => {
  const handlingStatusDataValues = Object.values(selectedState);
  const updateBookingStatusData = handlingStatusDataValues.flatMap(
    (customerBookings) => {
      return compact(
        Object.entries(customerBookings).map(([key, value]) => {
          if (value) {
            return {
              bookingNo: Number(key),
              handlingStatus: selectedBookingStatus.value,
              excludeReasonCode: excludeReason ?? null,
            };
          }

          return undefined;
        })
      );
    }
  );

  setLocalErrors([]);
  setModalErrors([]);

  const { data } = await setBookingStatus(updateBookingStatusData);
  const result =
    data?.productPrice.noShowLateHandlingBookings.updateBookingHandlingStatus;

  if (result && "errors" in result) {
    setLocalErrors([
      {
        message: "Unable to update booking statuses",
        errors: result.errors,
      },
    ]);
    setModalErrors([
      {
        message: "Unable to update booking statuses",
        errors: result.errors,
      },
    ]);
  } else {
    setExcludeReasonModalOpen(false);
    setLocalErrors([]);
    setModalErrors([]);
    deselectAllBookings();
  }
};

export const calculateThreshold = async (
  selectedState: CheckBoxSelectionState,
  selectedFilterState: WorkspaceState,
  calculateThreshold: (
    input: CalculateThresholdInput
  ) => Promise<
    FetchResult<
      CalculateThresholdMutation,
      Record<string, any>,
      Record<string, any>
    >
  >,
  setLocalErrors: React.Dispatch<
    React.SetStateAction<Array<FreightPricingPortalError>>
  >
) => {
  const calculateThresholdDataKeys = Object.keys(selectedState);
  const calculateThresholdDataValues = Object.values(selectedState);
  const calculateThresholdCustNosAndBookingNos =
    calculateThresholdDataValues.flatMap((customerBookings, index) => {
      return compact(
        Object.entries(customerBookings).map(([key, value]) => {
          if (value) {
            return {
              custNo: Number(calculateThresholdDataKeys[index]),
              bookingNo: Number(key),
            };
          }

          return undefined;
        })
      );
    });

  const calculateThresholdData = {
    year: selectedFilterState.year,
    month: selectedFilterState.month,
    bookings: calculateThresholdCustNosAndBookingNos,
  };

  setLocalErrors([]);
  const result = await calculateThreshold(calculateThresholdData);

  if (
    result.data?.productPrice.noShowLateHandlingBookings.calculateThreshold &&
    "errors" in
      result.data?.productPrice.noShowLateHandlingBookings.calculateThreshold
  ) {
    setLocalErrors([
      {
        message: "Unable to calculate threshold",
        errors:
          result.data.productPrice.noShowLateHandlingBookings.calculateThreshold
            .errors,
      },
    ]);
  }
};

export const createInvoices = async (
  selectedState: CheckBoxSelectionState,
  selectedFilterState: WorkspaceState,
  noteValue: string,
  createInvoicesMutation: (
    input: CreateInvoicesInput
  ) => Promise<
    FetchResult<
      CreateInvoicesMutation,
      Record<string, any>,
      Record<string, any>
    >
  >,
  setLocalErrors: React.Dispatch<
    React.SetStateAction<Array<FreightPricingPortalError>>
  >,
  deselectAllBookings: () => void
) => {
  const invoiceAndThresholdDataKeys = Object.keys(selectedState);
  const invoiceAndThresholdDataValues = Object.values(selectedState);
  const invoiceAndThresholdData = invoiceAndThresholdDataValues.flatMap(
    (customerBookings, index) => {
      return compact(
        Object.entries(customerBookings).map(([key, value]) => {
          if (value) {
            return {
              custNo: Number(invoiceAndThresholdDataKeys[index]),
              bookingNo: Number(key),
            };
          }

          return undefined;
        })
      );
    }
  );

  const createInvoicesAndCalculateThresholdData = {
    year: selectedFilterState.year,
    month: selectedFilterState.month,
    noShowLateHandlingNote: noteValue,
    bookings: invoiceAndThresholdData,
  };
  setLocalErrors([]);

  const result = await createInvoicesMutation(
    createInvoicesAndCalculateThresholdData
  );

  if (
    result.data?.productPrice.noShowLateHandlingInvoices.createInvoices &&
    "errors" in
      result.data?.productPrice.noShowLateHandlingInvoices.createInvoices
  ) {
    setLocalErrors([
      {
        message: "Could not create invoices",
        errors:
          result.data.productPrice.noShowLateHandlingInvoices.createInvoices
            .errors,
      },
    ]);
  }
};
