import { formatDateStringRangeWithSwedishTimeZone } from "@/common/dates/formatters";
import { FreightPricingPortalError } from "@/common/error/models/FreightPricingPortalError";
import { Indent, Text } from "@stenajs-webui/core";
import { StandardTableConfig } from "@stenajs-webui/grid";
import { PreviewPdfLink } from "../../head-agreement/rebate-agreement-documents/components/PreviewPdfLink";
import { DownloadPdfButton } from "../../head-agreement/rebate-agreement-documents/components/DownloadPdfButton";

export interface RebateDocumentTableRow {
  id: string;
  rebateAgreementId: string;
  fileName: string;
  negotiationYear: number;
  rebateAgreementValidFromDate: string;
  rebateAgreementValidToDate: string;
  writeHistory: {
    createdAt: {
      isoString: string;
    };
    createdBy: {
      id: string;
      name: string;
    };
  };
  customerNumber: number;
}

type RebateDocumentColumnKeys =
  | keyof Omit<
      RebateDocumentTableRow,
      | "customerNumber"
      | "rebateAgreementId"
      | "rebateAgreementValidFromDate"
      | "rebateAgreementValidToDate"
      | "writeHistory"
      | "id"
    >
  | "validity"
  | "download";
const ellipsisBreakWidth = "320";
const fileNameColumnWidth = "330px";
export const rebateDocumentsConfig = (
  error: FreightPricingPortalError | undefined,
  setError: React.Dispatch<
    React.SetStateAction<FreightPricingPortalError | undefined>
  >
) => {
  const rebateDocumentsConfig: StandardTableConfig<
    RebateDocumentTableRow,
    RebateDocumentColumnKeys
  > = {
    initialSortOrder: "validity",
    keyResolver: (item) => item.id,
    columns: {
      fileName: {
        itemValueResolver: (item) => item.fileName,
        renderCell: ({ label, value, item }) => (
          <PreviewPdfLink
            item={item}
            error={error}
            setError={setError}
            ellipsis={{ useEllipsis: true, breakWidth: ellipsisBreakWidth }}
          />
        ),
        columnLabel: "Filename",
        width: fileNameColumnWidth,
      },
      negotiationYear: {
        itemValueResolver: (item) => item.negotiationYear,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Text>{item.negotiationYear}</Text>
          </Indent>
        ),
        minWidth: "145px",
        columnLabel: "Negotiation year",
      },
      validity: {
        itemValueResolver: (item) => item.rebateAgreementValidFromDate,
        renderCell: ({ label, value, item }) => (
          <Indent>
            <Text>
              {formatDateStringRangeWithSwedishTimeZone({
                dateStringFrom: item.rebateAgreementValidFromDate,
                dateStringTo: item.rebateAgreementValidToDate,
              })}
            </Text>
          </Indent>
        ),
        minWidth: "190px",
        columnLabel: "Validity",
      },
      download: {
        itemValueResolver: (item) => item.id,
        renderCell: ({ label, value, item }) => (
          <DownloadPdfButton item={item} error={error} setError={setError} />
        ),
        columnLabel: "",
        width: "40px",
        justifyContentCell: "flex-end",
      },
    },

    columnOrder: ["fileName", "negotiationYear", "validity", "download"],
  };
  return rebateDocumentsConfig;
};
