import { Box, Row, Space, Txt } from "@stenajs-webui/core";
import { BreadCrumbs, stenaBusinessAgreement } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { HeadAgreementActionButtons } from "../../../head-agreement/details/components/HeadAgreementActionButtons";
import { CustomerNegotiationStatusEditorFetcher } from "../../customer-negotiation-status/components/CustomerNegotiationStatusEditorFetcher";
import { useCustomer } from "./UseCustomer";
import { routeFactory } from "@/RouteFactory";
import { PageHeader } from "@/common/components/page-header/PageHeader";
import { Crumb } from "@/common/components/bread-crumbs/Crumb";
import { NoExistingHeadAgreementActionButtons } from "../no-existing-head-agreement-action-buttons/NoExistingHeadAgreementActionButtons";

interface Props {
  customerId: string;
  negotiationYear?: number;
  headAgreement?: any;
  headAgreementBreadCrumbName?: string;
  loading?: boolean;
}

const border = `1px solid ${cssColor("--lhds-color-ui-500")}`;

export const CustomerHeadAgreementHeader: React.FC<Props> = React.memo(
  ({ customerId, headAgreement, loading, negotiationYear }) => {
    const currentYear = new Date().getUTCFullYear();
    const { loading: loadingCustomer, customer } = useCustomer(
      customerId,
      negotiationYear ?? currentYear
    );

    return (
      <PageHeader
        icon={stenaBusinessAgreement}
        title={"Header agreement"}
        loading={loading || loadingCustomer}
        breadcrumbs={
          <BreadCrumbs>
            <Crumb
              path={routeFactory.productAndPrice.customer.customerList()}
              label={"Negotiation plan"}
            />
            <Crumb label={customer?.breadCrumbName ?? ""} isLast />
          </BreadCrumbs>
        }
        contentRight={
          headAgreement?.id ? (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Txt color={cssColor("--lhds-color-ui-600")}>
                Neg. status({negotiationYear})
              </Txt>
              <Space num={2} />
              <Row width={"160px"} borderRadius={"4px"} height={"32px"}>
                <CustomerNegotiationStatusEditorFetcher
                  customerId={customerId}
                  negotiationYear={negotiationYear ?? currentYear}
                  borderRadius={"4px"}
                  border={border}
                />
              </Row>
              <Space num={2} />
              <HeadAgreementActionButtons
                customer={customer}
                placement={"bottom-start"}
                headAgreement={headAgreement}
              />
            </Box>
          ) : customer?.parent ? (
            <NoExistingHeadAgreementActionButtons
              parentCustomerId={customer.parent.id}
            />
          ) : null
        }
      />
    );
  }
);
