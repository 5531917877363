import { InfoBoxItem } from "../InfoBoxItem";

export const CustomerBaseInformation: React.FC<{
  custIndex: string;
  custNo: number;
  custStatNo: number | string | null;
  custInvoiceCurrency?: string;
}> = ({ custIndex, custNo, custStatNo, custInvoiceCurrency }) => (
  <>
    <InfoBoxItem leftContent={"Index"} rightContent={custIndex} />
    <InfoBoxItem leftContent={"No."} rightContent={custNo} />
    <InfoBoxItem leftContent={"Stat. No"} rightContent={custStatNo} />
    <InfoBoxItem
      leftContent={"Invoice curr."}
      rightContent={custInvoiceCurrency}
    />
  </>
);
