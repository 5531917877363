import { routeFactory } from "@/RouteFactory";
import { BlockedIcon } from "@/common/components/icons/BlockedIcon";
import { IconSize } from "@/common/components/icons/IconSize";
import { formatCompanyName } from "@/common/string/CompanyNameFormatter";
import { testIdConstants } from "@/common/test-id-constants/testIdConstants";
import { ZIndex } from "@/common/utils/ZIndexEnum";
import { gql, useApolloClient } from "@apollo/client";
import { Box, Row, Space, Text } from "@stenajs-webui/core";
import { Icon, stenaLockClosed, stenaSearch } from "@stenajs-webui/elements";
import { AsyncSelect } from "@stenajs-webui/select";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  GroupBase,
  SelectComponentsConfig,
  StylesConfig,
  components,
} from "react-select";
import {
  CustomerSearchNumberAndStatusFragment,
  CustomerStatusCode,
  CustomersSearchQuery,
  CustomersSearchQueryVariables,
} from "@/gql/graphql";

interface Props {}

const customersSearchQuery = gql`
  fragment CustomerSearchNumberAndStatus on Customer {
    id
    custIndex
    custStatus {
      id
      name
      code
    }
    custNo
    name
    parent {
      id
    }
    preselectedHeadAgreement {
      id
    }
  }
  query CustomersSearch($custNoAndStatus: CustomerGetByCustNoAndStatus!) {
    productPrice {
      customers {
        byCustomerNumberAndStatus(custNoAndStatus: $custNoAndStatus) {
          ...CustomerSearchNumberAndStatus
        }
      }
    }
  }
`;

const styles: StylesConfig<any> = {
  container: (base) => ({
    ...base,
    flex: 1,
    minWidth: 485,
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: "var(--swui-font-primary)",
    fontSize: "var(--swui-font-size-inputs)",
    color: "var(--lhds-color-ui-500)",
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontFamily: "var(--swui-font-primary)",
    fontSize: "var(--swui-font-size-inputs)",
  }),
  loadingMessage: (base) => ({
    ...base,
    fontFamily: "var(--swui-font-primary)",
    fontSize: "var(--swui-font-size-inputs)",
  }),
  control: (base, state) => ({
    ...base,
    borderRadius: "var(--swui-max-border-radius)",
    backgroundColor: "var(--swui-field-bg-enabled)",
    border: state.isFocused
      ? "2px solid var(--modern-blue)"
      : "1px solid var(--swui-select-border-color)",
  }),
  input: (base, _) => ({
    ...base,
    fontFamily: "var(--swui-font-primary)",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: ZIndex.highest,
  }),
};

const selectComponents: SelectComponentsConfig<
  CustomerOption,
  false,
  GroupBase<CustomerOption>
> = {
  IndicatorSeparator: () => null,
  DropdownIndicator: () => (
    <Icon icon={stenaSearch} style={{ paddingRight: 10 }} />
  ),
  Option: ({ children, ...props }) => {
    const { data: customer } = props.data as CustomerOption;

    return (
      <components.Option {...props}>
        <Box>
          <Row alignItems={"center"}>
            <Text variant={"bold"}>{formatCompanyName(customer.name)}</Text>
            {customer.custStatus?.code === CustomerStatusCode.Blocked && (
              <>
                <Space num={2} />
                <BlockedIcon size={IconSize.Small} />
                <Space num={1} />
                <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                  {customer.custStatus?.name}
                </Text>
              </>
            )}
            {customer.custStatus?.code === CustomerStatusCode.Closed && (
              <>
                <Space num={2} />
                <Icon icon={stenaLockClosed} />
                <Space num={1} />
                <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                  {customer.custStatus?.name}
                </Text>
              </>
            )}
          </Row>
          <Space />
          <Row>
            <Box width={140}>
              <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                {customer.custIndex} ({customer.custNo})
              </Text>
            </Box>
            {customer.parent && (
              <Text size="small" color={cssColor("--lhds-color-ui-500")}>
                Child Account: Parent No. {customer.parent.id}
              </Text>
            )}
          </Row>
        </Box>
      </components.Option>
    );
  },
};

interface CustomerOption {
  label: string;
  value: string;
  data: CustomerSearchNumberAndStatusFragment;
}

export const ApplicationSearch: React.FC<Props> = () => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const loadOptions = async (inputValue: string): Promise<CustomerOption[]> => {
    if (inputValue.length < 2) {
      return [];
    }

    const result = await client.query<
      CustomersSearchQuery,
      CustomersSearchQueryVariables
    >({
      query: customersSearchQuery,
      variables: {
        custNoAndStatus: {
          query: inputValue,
          allowedStatuses: Object.values(CustomerStatusCode),
        },
      },
    });

    return (
      result.data?.productPrice?.customers?.byCustomerNumberAndStatus.map(
        (customer) => ({
          data: customer,
          label: customer.custIndex,
          value: customer.id,
        })
      ) ?? []
    );
  };

  return (
    <Box data-testid={testIdConstants.globalCustomerSearch}>
      <AsyncSelect<CustomerOption>
        styles={styles}
        placeholder={"Search..."}
        components={selectComponents}
        loadOptions={loadOptions}
        value={null}
        cacheOptions
        noOptionsMessage={({ inputValue }) => {
          if (inputValue.length === 0) {
            return "Search for customer number or customer index";
          }
          if (inputValue.length < 2) {
            return "Type at least 2 characters to search";
          }

          return "No customers found";
        }}
        onChange={(event) => {
          if (event && !Array.isArray(event) && "data" in event) {
            const customer = event.data;
            navigate(
              customer.preselectedHeadAgreement
                ? routeFactory.productAndPrice.customer.customerHeadAgreement({
                    customerId: customer.id,
                    headAgreementId: customer.preselectedHeadAgreement.id,
                  })
                : routeFactory.productAndPrice.customer.customer({
                    customerId: customer.id,
                  })
            );
          }
        }}
      />
    </Box>
  );
};
