import { Customer, HeadAgreement } from "../../copy/types";
import * as React from "react";
import { PageHeader } from "../../../../../common/components/page-header/PageHeader";
import { Crumb } from "../../../../../common/components/bread-crumbs/Crumb";
import { routeFactory } from "../../../../../RouteFactory";
import { PageHeaderItem } from "../../../../../common/components/page-header/PageHeaderItem";
import { BreadCrumbs } from "@stenajs-webui/elements";

interface Props {
  customer: Customer;
  headAgreement: HeadAgreement;
}

export const ActivateHeadAgreementHeader: React.FC<Props> = ({
  customer,
  headAgreement,
}) => {
  return (
    <PageHeader
      title={"Activate header agreement"}
      breadcrumbs={
        <BreadCrumbs>
          <Crumb
            label={"Negotiation plan"}
            path={routeFactory.productAndPrice.customer.customerList()}
          />
          <Crumb
            label={customer?.breadCrumbName ?? ""}
            path={
              headAgreement.id
                ? routeFactory.productAndPrice.customer.customerHeadAgreement({
                    customerId: customer.id,
                    headAgreementId: headAgreement.id,
                  })
                : routeFactory.productAndPrice.customer.customer({
                    customerId: customer.id,
                  })
            }
          />

          <Crumb
            path={routeFactory.productAndPrice.headAgreement.activateHeadAgreement(
              {
                customerId: customer.id,
                headAgreementId: headAgreement.id,
              }
            )}
            label={"activate"}
            isLast
          />
        </BreadCrumbs>
      }
    >
      <PageHeaderItem label={"Cust. no."} value={customer.custNo} />
      <PageHeaderItem label={"Cust. name"} value={customer.name} />
      <PageHeaderItem label={"Cust. index"} value={customer.custIndex} />
    </PageHeader>
  );
};
